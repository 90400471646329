import './ItemDetail.css';

import { useState, } from 'react';
import { useDispatch } from 'react-redux';

import Counter from '../Counter/Counter';
import CartAddButton from '../CartAddButton/CartAddButton';
import Notification from '../Notification/Notification';
import SocialLoginModal from '../SocialLoginModal/SocialLoginModal';
import SocialLoginMobileModal from '../SocialLoginMobileModal/SocialLoginMobileModal';
import IconButton from '../IconButton/IconButton';
import ItemEditModal from '../ItemEditModal/ItemEditModal';
import ItemEditMobileModal from '../ItemEditMobileModal/ItemEditMobileModal';
import ToggleButton from '../ToggleButton/ToggleButton';
import ItemDeleteButton from '../ItemDeleteButton/ItemDeleteButton';
import ItemOptionsUpdateButton from '../ItemOptionsUpdateButton/ItemOptionsUpdateButton';

import { likeMenu, unlikeMenu, addMenuToCart, activateMenu, deactivateMenu } from '../../functions/menus';
import { saveUserInfo } from '../../_reducers/user';
import { saveItemInfo } from '../../_reducers/item';

export default function ItemDetail(props) {
    const dispatch = useDispatch();

    const [quantity, setQuantity] = useState(props.minimumOrderQuantity);
    const [option, setOption] = useState({"name":"basic","price":0});
    const [price, setPrice] = useState(props.price);

    const showSocialLoginModal = () => {
        if (window.innerWidth > 700) {
            const SocialLoginModal = document.getElementById("social-login-modal");
            SocialLoginModal.showModal();
        } else {
            const SocialLoginMobileModal = document.getElementById("social-login-mobile-modal");
            SocialLoginMobileModal.showModal();
        }
    }

    const addQuantity = () => {
        setQuantity(quantity + 1);
    }
    
    const subtractQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }

    if (quantity < props.minimumOrderQuantity) {
        setQuantity(props.minimumOrderQuantity);
    }

    const selectOption = (e) => {
        setOption(
            {"name":props.options[e.target.selectedIndex-1]["name"],
             "price":props.options[e.target.selectedIndex-1]["price"]});
        setPrice(props.options[e.target.selectedIndex-1]["price"]);
    }

    const [isItemEditMobileOn, setItemEditMobileOn] = useState(false);

    const openItemEditMobileModal = () => {
        setItemEditMobileOn(true);
    }

    const closeItemEditMobileModal = () => {
        setItemEditMobileOn(false);
    }

    const showItemEditModal = () => {
        if (window.innerWidth > 700) {
            const ItemEditModal = document.getElementById('item-edit-modal-'+props.id);
            ItemEditModal.showModal();
        } else {
            openItemEditMobileModal();
        }
    }

    const changePurchaseable = () => {
        if (props.isPurchaseable) {
            deactivateMenu(props.id).then(menu => {
                dispatch(saveItemInfo(menu));
            });
        } else {
            activateMenu(props.id).then(menu => {
                dispatch(saveItemInfo(menu));
            });
        }
    }

    let editButton;
    let purchaseableChangeButtonContainer;
    if ((props.userClass === 99) || (props.userClass === 'manager') || (props.userClass === 'Manager')) {
        editButton =
            <IconButton
                altText='edit'
                backgroundColor='rgba(0,0,0,0)'
                image={require('../../icons/edit_grey.png')}
                clickEvent={showItemEditModal}/>
        purchaseableChangeButtonContainer =
            <div className='item-detail-contents-row purchase-button'>
                <span className='item-detail-contents-title'>주문 가능 여부</span>
                <ToggleButton 
                    isOn={props.isPurchaseable} 
                    clickEvent={changePurchaseable}/>
            </div>
    }

    const isOptionItem = Array.isArray(props.options) && (props.options.length > 0);
    let optionsContainer;
    if (isOptionItem) {
        if (price < props.price) {
            setPrice(props.price);
        }
        const Options = props.options.map((option) => (
            <option 
                className='cart-modal-option'
                value={option["name"]} 
                key={option["name"]}>
                {option["name"]} - {option["price"].toLocaleString()}원
            </option>
        ))
        optionsContainer =
            <select name='option' className='cart-modal-option-container' onChange={selectOption}>
                <option value="" selected disabled hidden>옵션을 선택해주세요.</option>
                {Options}
            </select>
    } else {
        if (price !== props.price) {
            setPrice(props.price);
        }
    }

    let itemType;
    let typeTitle;
    let typeDetail;
    let typeDescription;
    switch (props.type) {
        case "delivery":
            itemType = '배송주문 상품';
            typeTitle = '배송';
            typeDetail = '택배 배송 (배송비용 4,000원 별도, 5만원 이상 무료 배송)';
            typeDescription= "12/7 배송 이후, 익일 ~ 2일 이내 도착"
            break;
        case "pickup":
            itemType = '픽업주문 상품';
            typeTitle = '픽업';
            typeDetail = '매장 방문하여 픽업';
            typeDescription= '픽업 최소 이틀전에 주문하고 매장에 방문해주세요. 최대 한달 후 날짜까지 주문 가능해요.';
            break;
        case "present":
            itemType = '선물배송 상품';
            typeTitle = '선물';
            typeDetail = '퀵 배송 (배송비용 별도)';
            typeDescription= '선택하신 날짜에 퀵 배송을 보내드려요. 최대 한달 후 날짜까지 주문 가능해요.';
            break;
        default:
            itemType = '배송주문 상품';
            typeTitle = '배송';
            typeDetail = '택배 배송';
            typeDescription= "14시 이전 주문은 당일에 배송 시작됩니다. 배송은 하루 ~ 이틀 걸려요."
    }

    const totalPrice = price * quantity;

    const animateHeartButton = () => {
        const heartButton = document.getElementById("item-detail-page-heart-button");
        heartButton.style.animationName = "beat";
        heartButton.style.animationDuration = "0.45s";
        heartButton.style.animationIterationCount = "1";
        setTimeout(() => heartButton.style.animation = "none", 500);
    }

    const likeButtonClickEvent = () => {
        if (props.isLiked) {
            unlikeMenu(props.id).then(user => {
                dispatch(saveUserInfo(user));
            });
        } else {
            likeMenu(props.id).then(user => {
                dispatch(saveUserInfo(user));
                animateHeartButton();
            });
        }
    }

    let LikeButton;
    if (props.isLogined) {
        if (props.isLiked) {
            LikeButton =
                <button className='item-detail-contents-like-button' onClick={() => likeButtonClickEvent()} id='item-detail-page-heart-button'>
                    <img className='item-detail-contents-like-button-image' src={require('../../icons/heart_red.png')} alt='like'></img>
                </button>
        } else {
            LikeButton =
                <button className='item-detail-contents-like-button' onClick={() => likeButtonClickEvent()} id='item-detail-page-heart-button'>
                    <img className='item-detail-contents-like-button-image' src={require('../../icons/heart_grey.png')} alt='like'></img>
                </button>
        }
    } else {
        LikeButton =
            <button className='item-detail-contents-like-button' onClick={() => showSocialLoginModal()} id='item-detail-page-heart-button'>
                <img className='item-detail-contents-like-button-image' src={require('../../icons/heart_grey.png')} alt='like'></img>
            </button>
    }
    

    const notiText = props.name + "를 담았어요.";
    const showPopupNotification = () => {
        const notification = document.getElementById("notification-item-detail-popup-"+props.id);        
        notification.style.display = "flex";
        setTimeout(() => notification.style.display = "none", 2000);
    }
    
    const cartAddAction = () => {
        if (props.isLogined) {
            showPopupNotification();
            let isOptionSelected = true;
        
        if (props.options) {
            if (props.options.length > 0) {
                if (option.name === "basic") {
                    isOptionSelected = false;
                }
            }
        }
        
        if (isOptionSelected) {
            addMenuToCart(props.id,quantity,option).then(user => dispatch(saveUserInfo(user)));
        } else {
            addMenuToCart(props.id,quantity,props.options[0]).then(user => dispatch(saveUserInfo(user)));
        }
        } else {
            if (props.isPurchaseable) {
                showSocialLoginModal();
            }
        }
    }

    let isOptionSelected = true;
    if (props.options && props.isLogined) {
        if (props.options.length > 0) {
            if (option.name === 'basic') {
                isOptionSelected = false;
            }
        }
    }    

    let itemDeleteButtonContainer;
    let itemOptionsUpdateButtonContainer;
    if ((props.userClass === 99) || (props.userClass === 'manager')) {
        itemDeleteButtonContainer = 
            <div className='item-detail-contents-row delete'>
                <ItemDeleteButton id={props.id}/>
            </div>
        itemOptionsUpdateButtonContainer = 
            <div className='item-detail-contents-row options-update'>
                <ItemOptionsUpdateButton options={props.options} id={props.id}/>
            </div>
    }

    let image;
    if (!props.image) {
        image = <img className='item-detail-thumbnail-image' src={require('../../images/question.png')} alt='thumbnail'></img>
    } else {
        image = <img className='item-detail-thumbnail-image' src={props.image} alt='thumbnail'></img>
    }

    return (
        <div className='item-detail'>
            {image}
            <div className='item-detail-contents-rows'>
                <div className='item-detail-contents-rows top'>
                    <div className='item-detail-contents-row'>
                        <span className='item-detail-contents-text type'>{itemType}</span>
                    </div>
                    <div className='item-detail-contents-row name'>
                        <div className='item-detail-contents-row name-left'>
                            <h2 className='item-detail-contents-header name'>{props.name}</h2>
                            {editButton}
                        </div>
                        {LikeButton}
                    </div>
                </div>
                <div className='item-detail-contents-row'>
                    <span className='item-detail-contents-text introduction'>{props.introduction}</span>
                </div>
                <div className='item-detail-contents-row price'>
                    <h2 className='item-detail-contents-header price'>{(Number(price)).toLocaleString()}원</h2>
                </div>
                <div className='item-detail-contents-row type'>
                    <span className='item-detail-contents-title'>{typeTitle}</span>
                    <div className='item-detail-contents-rows'>
                        <span className='item-detail-contents-text type-detail'>{typeDetail}</span>
                        <span className='item-detail-contents-text type-description'>{typeDescription}</span>
                    </div>
                </div>
                {optionsContainer}
                {itemOptionsUpdateButtonContainer}
                <div className='item-detail-contents-row cart'>
                    <span className='item-detail-contents-title'>상품 개수</span>
                    <Counter 
                        count={quantity} 
                        minimumQuantity={props.minimumOrderQuantity}
                        increaseEvent={addQuantity}
                        decreaseEvent={subtractQuantity}
                        inputEvent={setQuantity}
                        />
                </div>
                <div className='item-detail-contents-row total-price'>
                    <h2 className='item-detail-contents-header price'>{totalPrice.toLocaleString()}원</h2>
                </div>
                {purchaseableChangeButtonContainer}
                {itemDeleteButtonContainer}
                <div className='item-detail-contents-row cart-add-button'>
                    <CartAddButton 
                        textType='h3'
                        paddingTop={10}
                        paddingBottom={10}
                        cartAddAction={cartAddAction}
                        isPurchaseable={props.isPurchaseable}
                        isOptionSelected={isOptionSelected}
                        borderRadius={6}/>
                </div>
                <div className='item-detail-contents-row mobile-cart-add-button'>
                    <CartAddButton 
                        textType='h3'
                        paddingTop={14}
                        paddingBottom={14}
                        cartAddAction={cartAddAction}
                        isPurchaseable={props.isPurchaseable}
                        isOptionSelected={isOptionSelected}
                        borderRadius={16}/>
                </div>
            </div>
            <Notification 
                text={notiText} 
                id={"item-detail-popup-"+props.id}/>
            <SocialLoginModal/>
            <SocialLoginMobileModal/>
            <ItemEditModal
                id={props.id}
                name={props.name}
                intro={props.introduction}
                price={props.price}
                minimumOrderQuantity={props.minimumOrderQuantity}
                type={props.type}
                startDate={props.startDate}
                endDate={props.endDate}/>
            <ItemEditMobileModal
                isOn={isItemEditMobileOn}
                id={props.id}
                name={props.name}
                intro={props.introduction}
                price={props.price}
                minimumOrderQuantity={props.minimumOrderQuantity}
                startDate={props.startDate}
                endDate={props.endDate}
                closeEvent={closeItemEditMobileModal}
                type={props.type}/>
        </div>
    )
}

ItemDetail.defaultProps = {
    minimumOrderQuantity: 1,
    type: 'delivery',
    name: '상품명',
    introduction: '상품 소개',
    price: 0,
    isLiked: false,
    id: '000',
    options: [],
    isPurchaseable: false,
    isLogined: false,
    userClass: 0,
}