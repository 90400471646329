import axios from "axios";

import { getCookie } from "./cookie";

export const loadMenuClasses = async() => {
    try {
        const response = await axios.get('https://api.madinbakery.com/menuclass');
        let menuClasses = response.data.menuClass;
        return menuClasses
    } catch(err) {
        console.log(err);
    }
}

export const loadMenu = async(id) => {
    try {
        const response = await axios.get('https://api.madinbakery.com/menu/'+id);
        let menu = response.data.menu;
        return menu
    } catch(err) {
        console.log(err);
    }
}

export const loadMenus = async() => {
    try {
        const response = await axios.get('https://api.madinbakery.com/menu');
        let menus = response.data.menu;
        return menus
    } catch(err) {
        console.log(err);
    }
}

export const likeMenu = async(menuId) => {
    try {
        const token = getCookie('token');
        const response = await axios.post('https://api.madinbakery.com/user/like',{
            "token": token,
            "menuId": menuId, 
        });
        let user = response.data.user;
        return user
    } catch(err) {
        console.log(err);
    }
}

export const unlikeMenu = async(menuId) => {
    try {
        const token = getCookie('token');
        const response = await axios.delete('https://api.madinbakery.com/user/like',{
                data: {
                    "token": token,
                    "menuId": menuId
                } 
        });
        let user = response.data.user;
        return user
    } catch(err) {
        console.log(err);
    }
}

export const addMenuToCart = async(menuId, quantity,option) => {
    try {
        const token = getCookie('token');
        const response = await axios.post('https://api.madinbakery.com/user/cart',{
            "token": token,
            "menuId": menuId, 
            "quantity": Number(quantity),
            "option": option
        });
        let user = response.data.user;
        return user
    } catch(err) {
        console.log(err);
    } 
}

export const addCartMenuQuantity = async(menuId, option) => {
    try {
        const token = getCookie('token');
        const response = await axios.post('https://api.madinbakery.com/user/cart',{
            "token": token,
            "menuId": menuId, 
            "quantity": 1,
            "option": option
        });
        let user = response.data.user;
        return user
    } catch(err) {
        console.log(err);
    } 
}

export const subtractCartMenuQuantity = async(menuId, option) => {
    try {
        const token = getCookie('token');
        const response = await axios.post('https://api.madinbakery.com/user/cart',{
            "token": token,
            "menuId": menuId, 
            "quantity": -1,
            "option": option
        });
        let user = response.data.user;
        return user
    } catch(err) {
        console.log(err);
    } 
}

export const deleteCartMenu = async(menuId,option) => {
    try {
        const token = getCookie('token');
        const response = await axios.delete('https://api.madinbakery.com/user/cart',{
                data: {
                    "token": token,
                    "menuId": menuId,
                    "option": option
                } 
        });
        let user = response.data.user;
        return user
    } catch(err) {
        console.log(err);
    }
}

export const updateCartMenuCount = async(menuId,option,oldCount,newCount) => {
    try {
        const token = getCookie('token');
        const response = await axios.post('https://api.madinbakery.com/user/cart',{
            "token": token,
            "menuId": menuId, 
            "quantity": newCount-oldCount,
            "option": option
        });
        let user = response.data.user;
        return user
    } catch(err) {
        console.log(err);
    } 
}

export const uncheckCartMenu = async(id,orderType,option) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/user/cart',{
                "token": token,
                "menuId": id,
                "isChecked": false,
                "orderType": orderType,
                "option": option,
            });
            return response.data.user
        } catch(err) {
            console.log(err);
        }
    }
}

export const checkCartMenu = async(id,orderType,option) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/user/cart',{
                "token": token,
                "menuId": id,
                "isChecked": true,
                "orderType": orderType,
                "option": option,
            });
            return response.data.user
        } catch(err) {
            console.log(err);
        }
    }
}

export const updateMenuClass = async(id,name,intro,priority) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/menuclass/'+id,{
                "token": token,
                "name": name,
                "intro": intro,
                "priority": priority,
            });
            return response.data.menuClass
        } catch(err) {
            console.log(err);
        }
    }
}

export const addMenuClass = async(name,intro,priority,orderType) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.post('https://api.madinbakery.com/menuclass/',{
                "token": token,
                "name": name,
                "intro": intro,
                "priority": priority,
                "orderType": orderType,
                "isExpired": false,
            });
            return response.data.menuClass
        } catch(err) {
            console.log(err);
        }
    }
}

export const updateMenu = async(id,name,intro,price,minimumOrderQuantity,startDate,endDate) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/menu/'+id,{
                "token": token,
                "name": name,
                "intro": intro,
                "price": price,
                "minimumOrderQuantity":minimumOrderQuantity,
                "pickupStartDate":startDate,
                "pickupEndDate":endDate,
            });
            return response.data.menu
        } catch(err) {
            console.log(err);
        }
    }
}

export const updateMenuDescription = async(id,description) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/menu/'+id,{
                "token": token,
                "detail": description,
            });
            return response.data.menu
        } catch(err) {
            console.log(err);
        }
    }
}

export const activateMenu = async(id) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/menu/'+id,{
            "token": token,
            "purchaseable": true,
            });
            return response.data.menu
        } catch(err) {
            console.log(err);
        }
    }
}

export const deactivateMenu = async(id) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/menu/'+id,{
            "token": token,
            "purchaseable": false,
            });
            return response.data.menu
        } catch(err) {
            console.log(err);
        }
    }
}

export const addMenu = async(name,intro,price,tag,menuClassId,orderType) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.post('https://api.madinbakery.com/menu',{
            "token": token,
            "name": name,
            "price": price,
            "tag": tag,
            "menuClassId": menuClassId,
            "stock": 100,
            "orderType": orderType,
            "intro": intro,
            "minimumOrderQuantity": 1,
            });
            return response.data.menu
        } catch(err) {
            console.log(err);
        }
    }
}

export const deleteMenu = async(id) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.delete('https://api.madinbakery.com/menu/'+id,{
                data: {
                    "token": token,
                } 
            });
            return response.data.menu
        } catch(err) {
            console.log(err);
        }
    }
}

export const deleteMenuClass = async(id) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.delete('https://api.madinbakery.com/menuClass/'+id,{
                data: {
                    "token": token,
                } 
            });
            return response.data.menu
        } catch(err) {
            console.log(err);
        }
    }
}

export const updateMenuOptions = async(id,options) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/menu/'+id,{
                "token": token,
                "options": options,
            });
            return response.data.menu
        } catch(err) {
            console.log(err);
        }
    }
}