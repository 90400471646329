import './ToggleButton.css';

export default function ToggleButton(props) {
    const padding = String(Number(props.height.replace('px','')) -  Number(props.circleSize.replace('px',''))) + 'px';

    if (props.isOn) {
        return (
            <button className='toggle-button on' 
                    style={{'width':props.width,'height':props.height,'padding':padding}}
                    onClick={() => props.clickEvent()}>
                <div className='toggle-button-circle on' style={{'width':props.circleSize,'height':props.circleSize}}></div>
            </button>
        )
    } else {
        return (
            <button className='toggle-button off' 
                    style={{'width':props.width,'height':props.height,'padding':padding}}
                    onClick={() => props.clickEvent()}>
                <div className='toggle-button-circle off' style={{'width':props.circleSize,'height':props.circleSize}}></div>
            </button>
        )
    }
}

const defaultClickEvent = () => {

}

ToggleButton.defaultProps = {
    isOn: false,
    width: '54px',
    height: '28px',
    circleSize: '26px',
    clickEvent: defaultClickEvent,
}