import './OrderStateSelector.css';

import { useState } from 'react';

export default function OrderStateSelector(props) {
    const [status, setStatus] = useState('결제완료');

    if (status !== props.status) {
        setStatus(props.status);
    }

    const statusOptions = props.options.map((option) => {
        if (option === status) {
            return (
                <option 
                    className='order-state-selector-option'
                    value={status} 
                    key={option}
                    selected>
                    {option}
                </option>
            )
        } else {
            return (
                <option 
                    className='order-state-selector-option'
                    value={status} 
                    key={option}>
                    {option}
                </option>
            )
        }
    })
    const selectState = async(e) => {
        setStatus(props.options[e.target.selectedIndex]);
        props.selectEvent(props.options[e.target.selectedIndex]);
    }

    if (props.status !== '결제취소') {
        return (
            <div className='order-state-selector'>
                <select name='option' className='order-state-selector-options' onChange={selectState}>
                    {statusOptions}
                </select>
            </div>
        )
    } else {
        return (
            <div className='order-state-selector'>
                <span className='order-state-selector-text'>
                    주문 취소
                </span>
            </div>
        )
    }
    
}

const defaultClickEvent = () => {
    
}

OrderStateSelector.defaultProps = {
    status: '결제완료',
    selectEvent: defaultClickEvent,
    options: ['결제완료','상품준비중','픽업대기중','픽업완료'],
}