import '../default.css';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import Header from "../components/Header/Header";
import LikeMenuContentsContainer from '../components/LikeMenuContentsContainer/LikeMenuContentsContainer';

import { authUser } from '../functions/auth';
import { saveUserInfo, login, logout } from '../_reducers/user';
import { loadMenus } from '../functions/menus';
import { saveItemList } from '../_reducers/items';

export default function LikesView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const user = useSelector(state => state.user);
    const items = useSelector(state => state.items);

    useEffect(() => {
        loadMenus().then(menus => {
            dispatch(saveItemList(menus));
        });
        authUser().then((user) => {
            if (user) {
                dispatch(login());
                dispatch(saveUserInfo(user));
            } else {
                dispatch(logout());
                navigate('/');
            }
        })
    }, []);

    let cartItemNumber;
    if (user.deliveryCart && user.pickupCart && user.presentCart) {
        cartItemNumber = user.deliveryCart.length+user.pickupCart.length+user.presentCart.length;
    } else {
        cartItemNumber = 0;
    }
    
    return (
        <div className="page">
            <Header isLogined={user.isLogined} name={user.name} cartItemNumber={cartItemNumber} userClass={user.class}/>
            <div className='contents-container'>
                <div className='contents'>
                    <div className='contents-spacer'/>
                    <LikeMenuContentsContainer likeMenuList={user.likes} isLogined={user.isLogined} items={items.itemList}/>
                </div>
            </div>
        </div>
    )
}