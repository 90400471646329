import './OrderPickupTimeChangeMobileModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import TimeSelector from '../TimeSelector/TimeSelector';
import MobileModal from '../MobileModal/MobileModal';

import { updatePickupTime } from '../../functions/order';
import { authUser } from '../../functions/auth';
import { saveUserInfo } from '../../_reducers/user';

export default function OrderPickupTimeChangeMobileModal(props) {
    const dispatch = useDispatch();
    const componentId = 'order-pickup-time-change-modal-'+ props.id; 
    const [selectedTime, setSelectedTime] = useState('12:00');

    const timeSelectEvent = (time) => {
        setSelectedTime(time);
    }

    const changeButtonClickEvent = () => {
        updatePickupTime(props.id, selectedTime).then(
            () => {
                authUser().then((user) => dispatch(saveUserInfo(user)));
            }
        );
        props.closeEvent();
    }

    return (
        <MobileModal
            isOn={props.isOn} 
            closeEvent={props.closeEvent}
            isCompleteButtonNeeded={true}
            completeEvent={changeButtonClickEvent}
            completeText='변경하기'>
            <div className='order-pickup-time-change-mobile-modal-contents'>
                <div className='order-pickup-time-change-mobile-modal-title-row'>
                    <h2 className='order-pickup-time-change-mobiel-modal-title'>픽업 시간 변경</h2>
                </div>
                <div className='order-pickup-time-change-mobile-modal-row'>
                    <TimeSelector titleType='span' selectEvent={timeSelectEvent}/>
                </div>
            </div>
        </MobileModal>
    )
}

const defaultClickEvent = () => {
    
}

OrderPickupTimeChangeMobileModal.defaultProps = {
    isOn: false,
    closeEvent: defaultClickEvent,
    menus: [
        {name: "상품명1", quantity: 1, price: 2500},
        {name: "상품명2", quantity: 2, price: 3500},
        {name: "상품명3", quantity: 3, price: 4500},
    ],
}