import './ItemClassEditModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal/Modal';
import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

import { updateMenuClass, loadMenuClasses } from '../../functions/menus';
import { saveItemClassList } from '../../_reducers/items';

export default function ItemClassEditModal(props) {
    const dispatch = useDispatch();

    const componentId = 'item-class-edit-modal-' + props.id

    const [name, setName] = useState(props.name);
    const [intro, setIntro] = useState(props.intro);
    const [priority, setPriority] = useState(props.priority);

    const initializeInput = () => {
        setName(props.name);
        setIntro(props.intro);
        setPriority(props.priority);
    }

    const updateEvent = () => {
        updateMenuClass(props.id,name,intro,priority).then(menuClass => {
            loadMenuClasses().then(menuClasses => {
                dispatch(saveItemClassList(menuClasses));
            })}
        );       
    }

    let isEditable = (name && intro && (priority || (priority === 0)));

    let EditButton;
    if (isEditable) {
        EditButton =
            <form method="dialog" className='item-class-edit-modal-close-button-container'>
                <QuadrangleButton
                    paddingTop={12}
                    paddingBottom={12}
                    borderRadius={14}
                    text='수정하기'
                    clickEvent={updateEvent}/>
            </form>
    }

    

    return (
        <Modal id={componentId} closeEvent={initializeInput}>
            <div className='item-class-edit-modal'>
                <span className='item-class-edit-modal-title'>메뉴 클래스 이름</span>
                <input className='item-class-edit-modal-input' value={name} onChange={(e) => setName(e.target.value)}></input>

                <span className='item-class-edit-modal-title'>소개 문구</span>
                <input className='item-class-edit-modal-input' value={intro} onChange={(e) => setIntro(e.target.value)}></input>

                <span className='item-class-edit-modal-title'>우선순위</span>
                <input className='item-class-edit-modal-input' value={priority} onChange={(e) => setPriority(e.target.value)}></input>

                <div style={{'minHeight':'50px'}}></div>

                {EditButton}
            </div>
        </Modal>
    )
}

ItemClassEditModal.defaultProps = {
    name: '',
    phone: '',
    email: '',
    id: '000',
}