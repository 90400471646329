import './ItemAddModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal/Modal';
import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

import { addMenu, loadMenuClasses } from '../../functions/menus';
import { saveItemClassList } from '../../_reducers/items';

export default function ItemAddModal(props) {
    const dispatch = useDispatch();

    const componentId = 'item-add-modal-'+props.menuClassId;

    const [name, setName] = useState('');
    const [intro, setIntro] = useState('');
    const [price, setPrice] = useState(0);
    const [tag, setTag] = useState('');

    const initializeInput = () => {
        setName('');
        setIntro('');
        setPrice(0);
        setTag('');
    }

    const updateEvent = () => {
        addMenu(name, intro, price, tag, props.menuClassId, props.orderType).then(menu => {
            loadMenuClasses().then(menuClasses => {
                dispatch(saveItemClassList(menuClasses));
            })}
        );       
    }

    let isAddable = (name && intro && price && tag);

    let AddButton;
    if (isAddable) {
        AddButton =
            <form method="dialog" className='item-add-modal-close-button-container'>
                <QuadrangleButton
                    paddingTop={12}
                    paddingBottom={12}
                    borderRadius={14}
                    text='추가하기'
                    clickEvent={updateEvent}/>
            </form>
    }

    const updatePrice = (e) => {
        if (!isNaN(e.nativeEvent.data)) {
            const numberInput = e.target.value.replace(/-/g,'');
            console.log(numberInput);
            setPrice(numberInput);
        }
    }

    return (
        <Modal id={componentId} closeEvent={initializeInput}>
            <div className='item-add-modal'>
                <span className='item-add-modal-title'>이름</span>
                <input className='item-add-modal-input' value={name} onChange={(e) => setName(e.target.value)}></input>

                <span className='item-add-modal-title'>소개 문구</span>
                <input className='item-add-modal-input' value={intro} onChange={(e) => setIntro(e.target.value)}></input>

                <span className='item-add-modal-title'>가격</span>
                <input className='item-add-modal-input' value={price} onChange={(e) => updatePrice(e)}></input>

                <span className='item-add-modal-title'>태그</span>
                <input className='item-add-modal-input' value={tag} onChange={(e) => setTag(e.target.value)}></input>

                <div style={{'minHeight':'50px'}}></div>

                {AddButton}
            </div>
        </Modal>
    )
}

ItemAddModal.defaultProps = {
    orderType: 'pickup',
    menuClassId: '000',
}