export const getTodayDateString = () => {
    const todayYear = new Date().getFullYear();
    const todayMonth = new Date().getMonth() + 1;
    const todayDate = new Date().getDate();

    let todayMonthString;
    if (todayMonth < 10) {
        todayMonthString = '0' + String(todayMonth);
    } else {
        todayMonthString = String(todayMonth);
    }

    let todayDateString;
    if (todayDate < 10) {
        todayDateString = '0' + String(todayDate);
    } else {
        todayDateString = String(todayDate);
    }

    return (String(todayYear) + '-' + todayMonthString + '-' + todayDateString)
}

export const convertDateToString = (year,month,date) => {
    let monthString;
    if (Number(month) < 10) {
        monthString = '0' + String(month);
    } else {
        monthString = String(month);
    }

    let dateString;
    if (Number(date) < 10) {
        dateString = '0' + String(date);
    } else {
        dateString = String(date);
    }

    return (String(year) + '-' + monthString + '-' + dateString)
}