import '../default.css';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from "../components/Header/Header"
import ItemDetail from '../components/ItemDetail/ItemDetail';
import ItemDescription from '../components/ItemDescription/ItemDescription';
import StoreInfo from '../components/StoreInfo/StoreInfo';

import { loadMenu } from '../functions/menus';
import { saveItemInfo } from '../_reducers/item';
import { authUser } from '../functions/auth';
import { saveUserInfo, login, logout } from '../_reducers/user';

export default function ItemDetailView() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const item = useSelector(state => state.item);

    const { id } = useParams();

    useEffect(() => {
        loadMenu(id).then(menu => {
            dispatch(saveItemInfo(menu));
        });
        authUser().then((user) => {
            if (user) {
                dispatch(login());
                dispatch(saveUserInfo(user));
            } else {
                dispatch(logout());
            }
        })
    }, []);

    let cartItemNumber;
    if (user.deliveryCart && user.pickupCart && user.presentCart) {
        cartItemNumber = user.deliveryCart.length+user.pickupCart.length+user.presentCart.length;
    } else {
        cartItemNumber = 0;
    }

    let isLiked;
    if (user.likes) {
        isLiked = user.likes.includes(item.id);
    } else {
        isLiked = false;
    }

    return (
        <div className="page">
            <Header isLogined={user.isLogined} name={user.name} cartItemNumber={cartItemNumber} userClass={user.class}/>
            <div className='contents-container'>
                <div className='contents'>
                    <div style={{'minHeight':'40px'}}></div>
                    <ItemDetail
                        type={item.orderType}
                        name={item.name}
                        introduction={item.intro}
                        price={item.price}
                        minimumOrderQuantity={item.minimumOrderQuantity}
                        isLiked={isLiked}
                        options={item.options}
                        isPurchaseable={item.purchaseable}
                        startDate={item.pickupStartDate}
                        endDate={item.pickupEndDate}
                        isLogined={user.isLogined}
                        image={item.imageUrl}
                        id={item.id}
                        userClass={user.class}/>
                    <div style={{'minHeight':'20px'}}></div>
                    <ItemDescription
                        id={item.id}
                        name={item.name}
                        description={item.detail}
                        image1={item.detailImageUrl[0]}
                        image2={item.detailImageUrl[1]}
                        image3={item.detailImageUrl[2]}
                        gradientList={item.gradients}
                        userClass={user.class}
                        />
                    <div style={{'minHeight':'200px'}}/>
                    <StoreInfo/>
                </div>
            </div>
        </div>
    )
}
