import '../default.css';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Header from "../components/Header/Header"
import Banner from '../components/Banner/Banner';
import Footer from '../components/Footer/Footer';
import ItemContentsContainer from '../components/ItemContentsContainer/ItemContentsContainer';
import { KakaoRedirectHandler } from '../functions/kakaoLogin';
import { NaverRedirectHandler } from '../functions/naverLogin';
import StoreInfo from '../components/StoreInfo/StoreInfo';
import Notification from '../components/Notification/Notification';
import PresentGuide from '../components/PresentGuide/PresentGuide';
import DeliveryGuide from '../components/DeliveryGuide/DeliveryGuide';
import MenuClassAddButton from '../components/MenuClassAddButton/MenuClassAddButton';

import { loadMenuClasses } from '../functions/menus';
import { saveItemClassList } from '../_reducers/items';
import { getCookie, deleteCookie } from '../functions/cookie';
import { saveUserInfo, login, logout } from '../_reducers/user';
import { authUser } from '../functions/auth';

export default function MainView(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(state => state.user);
    const items = useSelector(state => state.items);

    const showPopupNotification = () => {
        const notification = document.getElementById("notification-social-login");
        notification.style.display = "flex";
        setTimeout(() => notification.style.display = "none", 2000);
    }

    useEffect(() => {
        loadMenuClasses().then(menuClasses => {
            dispatch(saveItemClassList(menuClasses));
        });
        const href = window.location.href;
        let params = new URL(href).searchParams;
        let code = params.get("code");
        const socialLogin = getCookie('socialLogin');
        if (socialLogin === 'kakao') {
            if (code != null) {
                KakaoRedirectHandler().then((user) => {
                    dispatch(login());
                    dispatch(saveUserInfo(user));
                    if (getCookie("redirectUrl")) {
                        navigate(getCookie("redirectUrl"));
                        deleteCookie('redirectUrl');
                    }
                    showPopupNotification();
                });
            }
        } 
        if (socialLogin === 'naver') {
            if (code != null) {
                NaverRedirectHandler().then((user) => {
                    dispatch(login());
                    dispatch(saveUserInfo(user));
                    if (getCookie("redirectUrl")) {
                        navigate(getCookie("redirectUrl"));
                        deleteCookie('redirectUrl');
                    }
                    showPopupNotification();
                });
            }
        } 
        authUser().then((user) => {
            if (user) {
                dispatch(login());
                dispatch(saveUserInfo(user));
            } else {
                dispatch(logout());
            }
        })
       
    }, []);
    
    let ItemClassList;
    if (items.itemClassList) {
        if (items.itemClassList.length > 0) {
            ItemClassList = items.itemClassList.map((itemClass,index) => {
                if (itemClass.orderType === props.status) {
                    return (
                        <ItemContentsContainer
                            groupName={itemClass.name}
                            intro={itemClass.intro}
                            items={itemClass.menus}
                            key={'item-group'+index}
                            isLogined={user.isLogined}
                            userClass={user.class}
                            isExpired={itemClass.isExpired}
                            priority={itemClass.priority}
                            id={itemClass._id}
                            orderType={itemClass.orderType}/>
                    )
                }
            })
        }
    }

    let cartItemNumber;
    if (user.deliveryCart && user.pickupCart && user.presentCart) {
        cartItemNumber = user.deliveryCart.length+user.pickupCart.length+user.presentCart.length;
    } else {
        cartItemNumber = 0;
    }

    let deliveryGuideContainer;
    if (props.status === 'delivery') {
        deliveryGuideContainer = <DeliveryGuide/>
    }

    let presentGuideContainer;
    if (props.status === 'present') {
        presentGuideContainer = <PresentGuide/>
    }

    let BannerContainer;
    if (props.status === 'pickup') {
        BannerContainer = <Banner/>
    }

    let MenuClassAddButtonContainer;
    if ((user.class === 99) || (user.class === 'manager')) {
        MenuClassAddButtonContainer = <MenuClassAddButton/>
    }

    return (
        <div className="page">
            <Header isLogined={user.isLogined} name={user.name} cartItemNumber={cartItemNumber} userClass={user.class}/>
            {BannerContainer}
            <div className='contents-container'>
                <div className='contents'>
                    {deliveryGuideContainer}
                    {presentGuideContainer}
                    {ItemClassList}
                    {MenuClassAddButtonContainer}
                </div>
            </div>
            <div style={{'minHeight':'200px'}}/>
            <StoreInfo/>
            <Footer isLogined={user.isLogined}/>
            <Notification
                text={user.name+'님 환영합니다.'} 
                id={'social-login'}/>
        </div>
    )
}

MainView.defaultProps = {
    status: 'delivery',
}