import './ItemDeleteButton.css';

import DeleteCheckModal from '../DeleteCheckModal/DeleteCheckModal';

import { deleteMenu } from '../../functions/menus';

export default function ItemDeleteButton(props) {
    const deleteButtonClickEvent = () => {
        const deleteCheckModal = document.getElementById('item-delete-check-modal');
        deleteCheckModal.showModal();
    }

    const deleteItem = () => {
        deleteMenu(props.id).then(menu => {
            window.location.href = '/';
        })
    }

    return (
        <div>
            <button className='item-delete-button' onClick={() => deleteButtonClickEvent()}>
                <span className='item-delete-button-text'>
                    삭제하기
                </span>
            </button>
            <DeleteCheckModal
                id={'item-delete-check-modal'}
                deleteEvent={deleteItem}/>
        </div>
        
    )
}

ItemDeleteButton.defaultProps = {
    id: '000',
}