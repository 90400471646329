import '../default.css';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Header from "../components/Header/Header"
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import HomeReturnButton from '../components/HomeReturnButton/HomeReturnButton';

import { saveUserInfo, login, logout } from '../_reducers/user';
import { authUser } from '../functions/auth';

export default function PrivacyPolicyView() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    useEffect(() => {
        authUser().then((user) => {
            if (user) {
                dispatch(login());
                dispatch(saveUserInfo(user));
            } else {
                dispatch(logout());
            }
        })
    }, []);

    let cartItemNumber;
    if (user.deliveryCart && user.pickupCart && user.presentCart) {
        cartItemNumber = user.deliveryCart.length+user.pickupCart.length+user.presentCart.length;
    } else {
        cartItemNumber = 0;
    }

    return (
        <div className="page padding-bottom-minimized">
            <Header isLogined={user.isLogined} name={user.name} cartItemNumber={cartItemNumber} userClass={user.class}/>
            <div className='contents-container'>
                <div className='contents'>
                    <div style={{"minHeight":'30px'}}/>
                    <PrivacyPolicy/>
                    <div style={{"minHeight":'100px'}}/>
                    <HomeReturnButton/>
                </div>
            </div>
        </div>
    )
}