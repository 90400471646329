import './PresentGuide.css';

export default function PresentGuide() {
    return (
        <div className='present-guide'>
            <h1 className='present-guide-title top'>Madin 선물 세트</h1>
            <div style={{'minHeight':'20px'}}></div>
            <h3 className='present-guide-contents brown'>달콤한 디저트로</h3>
            <h3 className='present-guide-contents'>감사의 마음을 전해보세요.</h3>
            <div style={{'minHeight':'20px'}}></div>
            <span className='present-guide-contents grey'>퀵 배송을 통해 빠르게 전달해드립니다!</span>
        </div>
    )
}