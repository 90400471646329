import './Item.css';

import CartButton from '../CartButton/CartButton';
import CartMobileButton from '../CartMobileButton/CartMobileButton';

export default function Item(props) {
    const isOptionItem = Array.isArray(props.options) && (props.options.length > 0);

    let priceText;
    if (isOptionItem) {
        const maximumOptionPriceItem = props.options.reduce((prev,value) => {
            return prev.price >= value.price ? prev : value
        });
        const minimumOptionPriceItem = props.options.reduce((prev,value) => {
            return prev.price <= value.price ? prev : value
        });
        if (maximumOptionPriceItem.price > minimumOptionPriceItem.price) {
            priceText = String(Number(minimumOptionPriceItem.price).toLocaleString()) + ' ~ ' + String(Number(maximumOptionPriceItem.price).toLocaleString()) + '원';
        } else {
            priceText = String(minimumOptionPriceItem.price.toLocaleString()) + '원';
        }
    } else {
        priceText = String(props.price.toLocaleString()) + '원'
    }

    let image;
    if (!props.image) {
        image = <img className='item-image' src={require('../../images/question.png')} alt='thumbnail'></img>
    } else {
        image = <img className='item-image' src={props.image} alt='thumbnail'></img>
    }

    return (
        <div className='item'>
            <a className='item-image-link' href={'/item/'+props.id}>
                {image}
            </a>
            <div className='item-cart-button-container widescreen'>
                <CartButton
                    name={props.name}
                    price={props.price}
                    minimumOrderQuantity={props.minimumOrderQuantity}
                    options={props.options}
                    id={props.id}
                    isPurchaseable={props.isPurchaseable}
                    isLogined={props.isLogined}
                    />
            </div>
            <div className='item-cart-button-container mobile'>
                <CartMobileButton
                    name={props.name}
                    price={props.price}
                    minimumOrderQuantity={props.minimumOrderQuantity}
                    options={props.options}
                    id={props.id}
                    isPurchaseable={props.isPurchaseable}
                    isLogined={props.isLogined}
                    />
            </div>
            <span className='item-text name'>{props.name}</span>
            <span className='item-text price'>{priceText}</span>
        </div>
    )
}

Item.defaultProps = {
    image: "",
    name: "상품명",
    price: 10000,
    minimumOrderQuantity: 1,
    id: '000',
    options: [],
    isPurchaseable: true,
    isLogined: false,
}