import './OrderListContentsContainer.css';

import UnitContentsContainer from '../UnitContentsContainer/UnitContentsContainer';
import OrderList from '../OrderList/OrderList';
import UnitBoxContainer from '../UnitBoxContainer/UnitBoxContainer';
import OrderSummary from '../OrderSummary/OrderSummary';

export default function OrderListContentsContainer(props) {
    let summary;
    let contents;
    if (props.orders) {
        if (props.orders.length) {
            summary = <OrderSummary orders={props.orders}/>
            contents = <OrderList orders={props.orders} isDateNeeded={props.isDateNeeded}/>
        } else {
            contents = 
                <UnitBoxContainer>
                    <div className='order-list-none-guide-container'>
                        <span className='order-list-none-guide'>
                            아직 주문내역이 없어요.
                        </span>
                    </div>
                </UnitBoxContainer>
        }
    } else {
        contents = 
            <UnitBoxContainer>
                <div className='order-list-none-guide-container'>
                    <span className='order-list-none-guide'>
                        아직 주문내역이 없어요.
                    </span>
                </div>
            </UnitBoxContainer>
    }
    return (
        <UnitContentsContainer title='주문 내역'>
            {summary}
            {contents}
        </UnitContentsContainer>
    )
}

OrderListContentsContainer.defaultProps = {
    isDateNeeded: false,
    orders: [
        {
            menus: [
                {
                    name: '제품명1',
                    price: 30000,
                    tag: '인기',
                    menuClass: '',
                    stock: 100,
                    orderType: 'delivery',
                    quantity: 1,
                    option: {},
                    isChecked: true,
                    imageUrl: '',
                    detailImageUrl: [],
                    intro: '',
                    options: [
                        {name: '1호', price: 30000},
                        {name: '2호', price: 40000},
                    ],
                    minimumOrderQuantity: 1,
                    gradients: [],
                    detail: '',
                    purchaseable: true,
                    id: '000',
                },
                {
                    name: '제품명2',
                    price: 3000,
                    tag: '인기',
                    menuClass: '',
                    stock: 100,
                    orderType: 'delivery',
                    quantity: 1,
                    option: {},
                    isChecked: true,
                    imageUrl: '',
                    detailImageUrl: [],
                    intro: '',
                    options: [
                        {name: '1호', price: 30000},
                        {name: '2호', price: 40000},
                    ],
                    minimumOrderQuantity: 1,
                    gradients: [],
                    detail: '',
                    purchaseable: true,
                    id: '000',
                }
            ],
            customer: {
                _id: '000',
                name: '장원영',
                phone: '010-1241-1201',
                email: 'abcd@gmail.com'
            },
            shipping: {
                _id: '125125',
                name: '안유진',
                phone: '010-0125-1251',
                basicAddress: '경기도 화성시 시청로102번길 11',
                detailAddress: '205동 1102호',
                postalCode: '00406',
                request: '',
                tag: '배송지',
                userId: '0102',
            },
            mileageUse: 0,
            presentDate: '',
            presentTime: '',
            deliveryDate: '',
            pickupDate: '',
            pickupTime: '',
            type: 'delivery',
            status: '결제완료',
            orderPrice: 33000,
            payedMoney: 33000,
            orderId: '1251251251',
            receipt: '',    
            paymentKey: '',
            deliveryId: '1251251',
            _id: '01010',
        }
    ],
}