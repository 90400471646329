import axios from "axios";
import { useNavigate } from "react-router-dom";

import { getCookie, deleteCookie } from "./cookie";

export const authUser  = async() => {
    try {
        const token = getCookie('token');
        if (token) {
            const authResponse = await axios.post('https://api.madinbakery.com/user/auth',
            {
            "token": token
            });
            if (authResponse.data.user) {
                return (authResponse.data.user);
            }
        }
    } catch(err) {
        console.log(err);
        deleteCookie('token');
    }
};

export const logoutUser = async () => {
    const token = getCookie('token');
    const socialLogin = getCookie('socialLogin');
    if (token) {
        await axios.post('https://api.madinbakery.com/user/logout',
            {
            "token": token,
            "socialLogin": socialLogin,
            }
        );
        deleteCookie('token');
        deleteCookie('socialLogin');
    }
}