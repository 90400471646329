import './ItemOptionsUpdateMobileModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MobileModal from '../MobileModal/MobileModal';

import { updateMenuOptions, loadMenu } from '../../functions/menus';
import { saveItemInfo } from '../../_reducers/item';

export default function ItemOptionsUpdateMobileModal(props) {
    const dispatch = useDispatch();

    const [options, setOptions] = useState(props.options);

    if (props.options & !options) {
        setOptions(props.options);
    }

    const initializeInput = () => {
        setOptions(props.options);
    }

    const updateOptions = () => {
        updateMenuOptions(props.menuId,options).then(menu => {
            loadMenu(props.menuId).then(menu => {
                dispatch(saveItemInfo(menu));
            })}
        );       
    }

    const closeEvent = () => {
        props.closeEvent();
        initializeInput();
    }

    const updateEvent = () => {
        updateOptions();
        props.closeEvent();
    }

    const updateOptionName = (index,name) => {
        const updatedOptions = options.map((option,optionIndex) => {
            if (optionIndex !== index ) {
                return option;
            } else {
                return {
                    ...option,
                    name: name,
                }
            }
        });
        setOptions(updatedOptions);
    }

    const deleteItemFromOptions = (index) => {
        options.splice(index,1);
        setOptions([...options]);
    }

    const updateOptionPrice = (index,price) => {
        const updatedOptions = options.map((option,optionIndex) => {
            if (optionIndex !== index ) {
                return option;
            } else {
                return {
                    ...option,
                    price: price,
                }
            }
        });
        setOptions(updatedOptions);
    }

    let optionInputs;
    if (options) {
        optionInputs = options.map((option,index) => {
            return (
                <div key={'mobile-modal-option'+String({index})}>
                    <div className='item-options-update-mobile-modal-row'>
                        <span className='item-options-update-mobile-modal-title'>옵션 {index+1}</span>
                        <button className='item-options-update-mobile-modal-delete-button' onClick={() => deleteItemFromOptions(index)}>
                            <span className='item-options-update-mobile-modal-delete-button-icon'>삭제하기</span>
                        </button>
                    </div>
                    <div className='item-options-update-mobile-modal-input-container'>
                        <input className='item-options-update-mobile-modal-input' value={option.name} onChange={(e) => updateOptionName(index,e.target.value)}></input>
                        <input className='item-options-update-mobile-modal-input' value={option.price} onChange={(e) => updateOptionPrice(index,e.target.value)}></input>
                    </div>
                </div>
            )            
        })
    }

    let isUpdatable = true;
    options.map((option) => {
        if (!option.name || !option.price) {
            isUpdatable = false;
        }
    })

    const addOptions = () => {        
        setOptions([...options, {"name":"","price":0}]);
    }

    const OptionAddButton = 
        <div className='item-options-update-mobile-modal-add-button-container'>
            <button className='item-options-update-mobile-modal-add-button' onClick={() => addOptions()}>
                <img className='item-options-update-mobile-modal-add-button-icon'
                     src={require('../../icons/plus_grey.png')}></img>
                <span className='item-options-update-mobile-modal-add-button-text'>옵션 추가하기</span>
            </button>
        </div>

    return (
        <MobileModal isOn={props.isOn} 
                     closeEvent={closeEvent} 
                     isCompleteButtonNeeded={isUpdatable}
                     completeEvent={updateEvent}
                     completeText='수정하기'>
            <div className='item-options-update-mobile-modal'>
                {optionInputs}
                {OptionAddButton}
                <div style={{'minHeight':'20px'}}></div>
            </div>
        </MobileModal>
    )
}

ItemOptionsUpdateMobileModal.defaultProps = {
    name: '',
    phone: '',
    email: '',
    isOn: false,
    id: '000',
}