import './CartAddButton.css';

import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

export default function CartAddButton(props) {
    if (props.isPurchaseable) {
        if (props.isOptionSelected) {
            return (
                <QuadrangleButton
                    text='장바구니에 담기'
                    textType={props.textType}
                    width={props.width}
                    paddingTop={props.paddingTop}
                    paddingBottom={props.paddingBottom}
                    paddingLeft={props.paddingLeft}
                    paddingRight={props.paddingRight}
                    clickEvent={props.cartAddAction}
                    borderRadius={props.borderRadius}
                    />
            )
        } else {
            return (
                <QuadrangleButton
                    text='옵션을 선택해주세요.'
                    textType={props.textType}
                    width={props.width}
                    paddingTop={props.paddingTop}
                    paddingBottom={props.paddingBottom}
                    paddingLeft={props.paddingLeft}
                    paddingRight={props.paddingRight}
                    borderRadius={props.borderRadius}
                    backgroundColor='#aaa'
                    borderColor='#aaa'
                    />
            )
        }
        
    } else {
        return (
            <QuadrangleButton
                text='구매할 수 없는 상품이에요.'
                textType={props.textType}
                width={props.width}
                paddingTop={props.paddingTop}
                paddingBottom={props.paddingBottom}
                paddingLeft={props.paddingLeft}
                paddingRight={props.paddingRight}
                borderRadius={props.borderRadius}
                backgroundColor='#aaa'
                borderColor='#aaa'
                />
        )
    }
}

const defaultClickEvent = () => {
    
}

CartAddButton.defaultProps = {
    width: '100%',
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 20,
    paddingRight: 20,
    textType: 'span',
    cartAddAction: defaultClickEvent,
    isPurchaseable: true,
    borderRadius: 6,
    isOptionSelected: true,
}