import './HomeReturnButton.css';

import { useNavigate } from 'react-router-dom';

import QuadrangleButton from "../QuadrangleButton/QuadrangleButton";

export default function HomeReturnButton() {
    const navigate = useNavigate();

    const moveToHome = () => {
        navigate('/');
    }

    return (
        <div className="home-return-button-container">
            <div className='home-return-button'>
                <QuadrangleButton
                    text='홈으로 돌아가기'
                    textType='h3'
                    paddingTop='10px'
                    paddingBottom='10px'
                    clickEvent={moveToHome}/>    
            </div>
            <div className='home-return-button mobile'>
                <QuadrangleButton
                        text='홈으로 돌아가기'
                        textType='h3'
                        paddingTop={14}
                        paddingBottom={14}
                        borderRadius={16}
                        clickEvent={moveToHome}/>    
            </div>
        </div>
    )
}