import './CartItem.css';

import { useDispatch } from 'react-redux';

import UnitBoxContainer from "../UnitBoxContainer/UnitBoxContainer";
import Counter from '../Counter/Counter';
import CheckButton from '../CheckButton/CheckButton';
import DeleteCheckModal from '../DeleteCheckModal/DeleteCheckModal';

import { addCartMenuQuantity, subtractCartMenuQuantity, deleteCartMenu, checkCartMenu, uncheckCartMenu, updateCartMenuCount} from '../../functions/menus';
import { saveUserInfo } from '../../_reducers/user';

export default function CartItem(props) {
    const dispatch = useDispatch();

    const openDeleteCheckModal = () => {
        const CartDeleteCheckModal = document.getElementById('cart-item-delete-modal-' + props.id + '-' + props.option.name) ;
        CartDeleteCheckModal.showModal();
    }

    let itemName;
    let itemPrice;
    if (props.option) {
        if (props.option.name && props.option.price) {
            itemName = props.name + ' ' + props.option.name;
            itemPrice = props.option.price * props.count;
        } else {
            itemName = props.name;
            itemPrice = props.price * props.count;
        }
    } else {
        itemName = props.name;
        itemPrice = props.price;
    }

    const addButtonEvent = () => {
        addCartMenuQuantity(props.id,props.option).then(user => dispatch(saveUserInfo(user)));
    }

    const minusButtonEvent = () => {
        if (props.count > props.minimumOrderQuantity) subtractCartMenuQuantity(props.id,props.option).then(user => dispatch(saveUserInfo(user)));
    }

    const deleteMenuEvent = () => {
        deleteCartMenu(props.id,props.option).then(user => dispatch(saveUserInfo(user)));
    }

    const inputEvent = (newCount) => {
        if (newCount >= props.minimumOrderQuantity) {
            updateCartMenuCount(props.id,props.option,props.count,newCount).then(user => dispatch(saveUserInfo(user)));
        } 
    }

    const checkButtonClickEvent = () => {
        if (props.isChecked) {
            uncheckCartMenu(props.id,props.orderType,props.option).then((user)=> dispatch(saveUserInfo(user)));
        } else {
            checkCartMenu(props.id,props.orderType,props.option).then((user) => dispatch(saveUserInfo(user)));
        }
    }

    let ItemImage;
    if (props.itemImage) {
        ItemImage = <img src={props.itemImage} className='cart-item-image' alt='item'></img>
    } else {
        ItemImage = <img src={require('../../images/question.png')} className='cart-item-image' alt='item'></img>
    }

    return (
        <UnitBoxContainer>
            <div className="cart-item">
                <CheckButton size={24} isOn={props.isChecked} clickEvent={checkButtonClickEvent}/>
                {ItemImage}
                <h3 className='cart-item-name'>{itemName}</h3>
                <div className='cart-item-spacer'></div>
                <div className='cart-item-right-contents'>
                    <Counter 
                        count={props.count} 
                        increaseEvent={addButtonEvent} 
                        decreaseEvent={minusButtonEvent} 
                        minimumQuantity={props.minimumOrderQuantity}
                        inputEvent={inputEvent}/>
                    <h2 className='cart-item-price'>{itemPrice.toLocaleString()}원</h2>
                </div>
                <button className='cart-item-delete-button' onClick={() => openDeleteCheckModal()}>
                    <span className='cart-item-delete-button-text'>삭제하기</span>
                </button>
                <DeleteCheckModal 
                    id={'cart-item-delete-modal-' + props.id + '-' + props.option.name}
                    deleteEvent={deleteMenuEvent}/>
            </div>
        </UnitBoxContainer>
    )
}

CartItem.defaultProps = {
    id: '000',
    isChecked: false,
    name: "상품명",
    checkedImage: '',
    uncheckedImage: '',
    price: 5000,
    count: 1,
    minimumOrderQuantity: 1,
    option: {},
    orderType: 'delivery',
}