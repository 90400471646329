import '../default.css';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

import Header from "../components/Header/Header"
import UnderlineNavContainer from '../components/UnderlineNavContainer/UnderlineNavContainer';
import UserClass from '../components/UserClass/UserClass';
import ManagerCalendar from '../components/ManagerCalendar/ManagerCalendar';
import OrderListContentsContainer from '../components/OrderListContentsContainer/OrderListContentsContainer';
import Footer from '../components/Footer/Footer';
import ToggleButton from '../components/ToggleButton/ToggleButton';

import { authUser } from '../functions/auth';
import { saveUserInfo, login, logout } from '../_reducers/user';
import { getDailyOrderList } from '../functions/manage';
import { getTodayDateString, convertDateToString } from '../functions/date';
import { saveOrders, saveRecentOrders } from '../_reducers/order';
import { getRecentMonthlyOrderList } from '../functions/manage';

export default function ManageView(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const user = useSelector(state => state.user);
    const order = useSelector(state => state.order);

    const type = window.location.pathname.split('/')[2];

    const [isCalendarStyle, setCalendarStyle] = useState(true);

    const calendarDayClickEvent = (year,month,date) => {
        getDailyOrderList(type,convertDateToString(year,month,date)).then((orderList) => {
            dispatch(saveOrders(orderList));
        })
    }

    let CalendarConatiner;
    if (isCalendarStyle) {
        CalendarConatiner = 
            <ManagerCalendar clickEvent={calendarDayClickEvent}/>
    }

    useEffect(() => {
        authUser().then((user) => {
            if (user) {
                dispatch(login());
                dispatch(saveUserInfo(user));
                if ((user.class !== 99) && (user.class !== 'manager') && (user.class !== 'Manager')) {
                    navigate('/');
                }
            } else {
                dispatch(logout());
                navigate('/');
            }
        })
        getDailyOrderList(type,getTodayDateString()).then((orderList) => {
            dispatch(saveOrders(orderList));
        })
    }, []);

    let cartItemNumber;
    if (user.deliveryCart && user.pickupCart && user.presentCart) {
        cartItemNumber = user.deliveryCart.length+user.pickupCart.length+user.presentCart.length;
    } else {
        cartItemNumber = 0;
    }

    const toggleButtonClickEvent = () => {
        if (isCalendarStyle) {
            setCalendarStyle(false);
            getRecentMonthlyOrderList(type).then(orderList => {
                dispatch(saveRecentOrders(orderList));
            })
        } else {
            setCalendarStyle(true);
        }
    }
    
    let orderList;
    if (isCalendarStyle) {
        if (type === 'pickup') {
            orderList = <OrderListContentsContainer orders={order.orders.sort(function(a,b){return (a.pickupTime > b.pickupTime)})}/>
        } else {
            orderList = <OrderListContentsContainer orders={order.orders}/>
        }
    } else {
        if (type === 'pickup') {
            orderList = <OrderListContentsContainer 
                            orders={order.recentOrders.sort(function(a,b) {
                                if (a.pickupDate > b.pickupDate) return 1;
                                if (a.pickupDate < b.pickupDate) return -1;
                                if (a.pickupTime > b.pickupTime) return 1;
                                if (a.pickupTime < b.pickupTime) return -1;
                            })}
                            isDateNeeded={true}/>
        } else if (type === 'present') {
            orderList = <OrderListContentsContainer 
                            orders={order.recentOrders.sort(function(a,b) {return (a.presentDate > b.presentDate)})}
                            isDateNeeded={true}/>
        } else if (type === 'delivery') {
            orderList = <OrderListContentsContainer 
                            orders={order.recentOrders.sort(function(a,b) {return (a.createdAt > b.createdAt)})}
                            isDateNeeded={true}/>
        } else {
            orderList = <OrderListContentsContainer 
                            orders={order.recentOrders.sort(function(a,b) {return (a.createdAt > b.createdAt)})}
                            isDateNeeded={true}/>
        }
    }

    return (
        <div className="page">
            <Header isLogined={user.isLogined} name={user.name} cartItemNumber={cartItemNumber} userClass={user.class}/>
            <div className='contents-container'>
                <div className='contents'>
                    <div style={{'minHeight':'30px'}}></div>
                    <UserClass class={user.class} name={user.name}/>
                </div>
                <div className='contents-spacer cart'/>
                <UnderlineNavContainer 
                    navTextList={['픽업주문','선물주문','배송주문']} 
                    linkList={['/manage/pickup','/manage/present','/manage/delivery']}
                    status={props.status}/>
                <div className='contents'>
                    <div style={{'minHeight':'20px'}}></div>
                    <div style={{'width':'100%','maxWidth':'500px','display':'flex','flexDirection':'row','justifyContent':'space-between'}}>
                        <span style={{'color':'#aaa'}}>달력으로 보기</span>
                        <ToggleButton isOn={isCalendarStyle} clickEvent={toggleButtonClickEvent}/>
                    </div>
                    <div style={{'minHeight':'20px'}}></div>
                    {CalendarConatiner}
                    <div style={{'minHeight':'20px'}}></div>
                    {orderList}
                </div>
            </div>
            <Footer isLogined={user.isLogined}/>
        </div>
    )
}

ManageView.defaultProps = {
    status: '배송주문',
}