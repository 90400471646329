import './OrderCompleteGuide.css';

export default function OrderCompleteGuide() {
    return (
        <div className='order-complete-guide'>
            <img className='order-complete-guide-image' src={require('../../images/order_complete.png')} alt='complete'></img>
            <div style={{'minHeight':'20px'}}></div>
            <h1 className='order-complete-guide-header1'>
                주문이 완료되었어요!
            </h1>
            <div style={{'minHeight':'10px'}}></div>
            <h3 className='order-complete-guide-header3'>
                아래에서 상세 주문 내역을 확인해보세요.
            </h3>
        </div>
    )
}