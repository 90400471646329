import './ItemClassEditMobileModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MobileModal from '../MobileModal/MobileModal';

import { updateMenuClass, loadMenuClasses } from '../../functions/menus';
import { saveItemClassList } from '../../_reducers/items';

export default function ItemClassEditMobileModal(props) {
    const dispatch = useDispatch();

    const [name, setName] = useState(props.name);
    const [intro, setIntro] = useState(props.intro);
    const [priority, setPriority] = useState(props.priority);

    const initializeInput = () => {
        setName(props.name);
        setIntro(props.intro);
        setPriority(props.priority);
    }

    const editButtonClickEvent = () => {
        updateMenuClass(props.id,name,intro,priority).then(menuClass => {
            loadMenuClasses().then(menuClasses => {
                dispatch(saveItemClassList(menuClasses));
            })}
        );       
    }

    let isEditable = (name && intro && (priority || (priority === 0)));

    const closeEvent = () => {
        props.closeEvent();
        initializeInput();
    }
    const updateEvent = () => {
        editButtonClickEvent();
        props.closeEvent();
    }

    return (
        <MobileModal isOn={props.isOn} 
                     closeEvent={closeEvent} 
                     isCompleteButtonNeeded={isEditable}
                     completeEvent={updateEvent}
                     completeText='수정하기'>
            <div className='item-class-edit-mobile-modal'>
                <span className='item-class-edit-mobile-modal-title'>메뉴 클래스 이름</span>
                <input className='item-class-edit-mobile-modal-input' value={name} onChange={(e) => setName(e.target.value)}></input>

                <span className='item-class-edit-mobile-modal-title'>소개 문구</span>
                <input className='item-class-edit-mobile-modal-input' value={intro} onChange={(e) => setIntro(e.target.value)}></input>

                <span className='item-class-edit-mobile-modal-title'>우선순위</span>
                <input className='item-class-edit-mobile-modal-input' value={priority} onChange={(e) => setPriority(e.target.value)}></input>

                <div style={{'minHeight':'20px'}}></div>
            </div>
        </MobileModal>
    )
}

ItemClassEditMobileModal.defaultProps = {
    name: '',
    phone: '',
    email: '',
    isOn: false,
    id: '000',
}