import './MenuClassAddButton.css';

import { useState } from 'react';

import ItemClassAddModal from '../ItemClassAddModal/ItemClassAddModal';
import ItemClassAddMobileModal from '../ItemClassAddMobileModal/ItemClassAddMobileModal';

export default function MenuClassAddButton(props) {
    const [isMobileModalOn, setMobileModalOn] = useState(false);

    const showItemClassAddModal = () => {
        if (window.innerWidth > 700) {
            const itemClassAddModal = document.getElementById('item-class-add-modal');
            itemClassAddModal.showModal();
        } else {
            setMobileModalOn(true);
        }
    }

    const closeMobileModal = () => {
        setMobileModalOn(false);
    }

    return (
        <div className='menuclass-add-button-container'>
            <button className='menuclass-add-button' onClick={() => showItemClassAddModal()}>
                <img src={require('../../icons/plus_grey.png')} 
                     className='menuclass-add-button-plus-icon'
                     alt='plus'></img>
                <h3 className='menuclass-add-button-text'>메뉴 클래스 추가하기</h3>
            </button>
            <ItemClassAddModal/>
            <ItemClassAddMobileModal isOn={isMobileModalOn} closeEvent={closeMobileModal}/>
        </div>
    )
}

MenuClassAddButton.defaultProps = {

}