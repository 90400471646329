import './ManagerCalendar.css';

import Calendar from '../Calendar/Calendar';

export default function ManagerCalendar(props) {
    return (
        <Calendar
            isDayLimited={false}
            clickEvent={props.clickEvent}/>
    )
}

const defaultClickEvent = () => {
    
}

ManagerCalendar.defaultProps = {
    isDayLimited: false,
    clickEvent: defaultClickEvent,
}