import './ItemOptionsUpdateButton.css';

import { useState } from 'react';

import ItemOptionsUpdateModal from '../ItemOptionsUpdateModal/ItemOptionsUpdateModal';
import ItemOptionsUpdateMobileModal from '../ItemOptionsUpdateMobileModal/ItemOptionsUpdateMobileModal';

export default function ItemOptionsUpdateButton(props) {
    const [isMobileModalOn, setMobileModalOn] = useState(false);

    const updateButtonClickEvent = () => {
        if (window.innerWidth > 700) {
            const ItemOptionsUpdateModal = document.getElementById('item-options-update-modal');
            ItemOptionsUpdateModal.showModal();
        } else {
            setMobileModalOn(true);
        }
    }

    const closeMobileModal = () => {
        setMobileModalOn(false);
    }

    return (
        <div>
            <button className='item-options-updatebutton' onClick={() => updateButtonClickEvent()}>
                <span className='item-options-updatebutton-text'>
                    옵션 수정하기
                </span>
            </button>
            <ItemOptionsUpdateModal 
                options={props.options} 
                menuId={props.id}/>
            <ItemOptionsUpdateMobileModal
                isOn={isMobileModalOn}
                options={props.options} 
                menuId={props.id}
                closeEvent={closeMobileModal}/>
        </div>
        
    )
}

ItemOptionsUpdateButton.defaultProps = {
    id: '000',
}