import '../default.css';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from "../components/Header/Header"
import UnderlineNavContainer from '../components/UnderlineNavContainer/UnderlineNavContainer';
import CartItemContentsContainer from '../components/CartItemContentsContainer/CartItemContentsContainer';
import OrderButton from '../components/OrderButton/OrderButton';

import { refreshCart } from '../functions/user';
import { authUser } from '../functions/auth';
import { saveUserInfo, login, logout } from '../_reducers/user';

export default function CartView(props) {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const moveToOrderPage = () => {
        let orderPage;
        switch (props.status) {
            case "배송주문":
                orderPage = '/order/delivery';
                break;
            case "선물주문":
                orderPage = '/order/present';
                break;
            case "픽업주문":
                orderPage = '/order/pickup';
                break;
            default:
                orderPage = '/order/delivery';
        }
        window.location.href = orderPage;
    }

    let cartItemList;
    let orderType;
    switch (props.status) {
        case "배송주문":
            cartItemList = user.deliveryCart;
            orderType = 'delivery';
            break;
        case "선물주문":                
            cartItemList = user.presentCart;
            orderType = 'present';
            break;
        case "픽업주문":
            cartItemList = user.pickupCart;
            orderType = 'pickup';
            break;
        default:                
            cartItemList = user.deliveryCart;
            orderType = 'pickup';
    }

    let checkedCartItemNumber = 0;
    cartItemList.map((cartItem) => {
        if (cartItem.isChecked) {
            checkedCartItemNumber = checkedCartItemNumber + 1;
        }
    })

    let orderButton;
    if (checkedCartItemNumber > 0) {
        if (cartItemList.length > 0) {
            orderButton = 
                <OrderButton clickEvent={moveToOrderPage}/>
        }
    }

    useEffect(() => {
        authUser().then((user) => {
            if (user) {
                dispatch(login());
                dispatch(saveUserInfo(user));
            } else {
                dispatch(logout());
                navigate('/');
            }
        });
        refreshCart(orderType).then((user) => {
            if (user) {
                dispatch(login());
                dispatch(saveUserInfo(user));
            } else {
                dispatch(logout());
                navigate('/');
            }
        })
    }, []);

    let cartItemNumber;
    if (user.deliveryCart && user.pickupCart && user.presentCart) {
        cartItemNumber = user.deliveryCart.length+user.pickupCart.length+user.presentCart.length;
    } else {
        cartItemNumber = 0;
    }

    return (
        <div className="page">
            <Header isLogined={user.isLogined} name={user.name} cartItemNumber={cartItemNumber} userClass={user.class}/>
            <div className='contents-container'>
                <div className='contents-spacer cart'/>
                <UnderlineNavContainer 
                    navTextList={['픽업주문','선물주문','배송주문']} 
                    linkList={['/cart/pickup','/cart/present','/cart/delivery']} 
                    status={props.status}/>
                <div className='contents'>
                    <div style={{'minHeight':'20px'}}></div>
                    <CartItemContentsContainer cartItemList={cartItemList}/>
                    <div className='contents-spacer'/>
                </div>
                {orderButton}
            </div>
        </div>
    )
}

CartView.defaultProps = {
    status: '배송주문',
}