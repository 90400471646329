import './DeliveryGuide.css';

export default function DeliveryGuide() {
    return (
        <div className='delivery-guide'>
            <h1 className='delivery-guide-title top'>Madin 딜리버리</h1>
            <div style={{'minHeight':'20px'}}></div>
            <h3 className='delivery-guide-contents brown'>1차 배송(12/7) 주문이</h3>
            <h3 className='delivery-guide-contents'>종료되었어요.</h3>
            <div style={{'minHeight':'20px'}}></div>
            <span className='delivery-guide-contents grey'>2차 배송을 기다려주세요!</span>
        </div>
    )
}