import './ItemContentsContainer.css';

import { useState } from 'react';

import ItemList from "../ItemList/ItemList";
import IconButton from '../IconButton/IconButton';
import ItemClassEditModal from '../ItemClassEditModal/ItemClassEditModal';
import ItemClassEditMobileModal from '../ItemClassEditMobileModal/ItemClassEditMobileModal';
import ItemClassDeleteButton from '../ItemClassDeleteButton/ItemClassDeleteButton';

export default function ItemContentsContainer(props) {
    const [isMobileModalOn, setMobileModalOn] = useState(false);

    const openMobileModal = () => {
        setMobileModalOn(true);
    }

    const closeMobileModal = () => {
        setMobileModalOn(false);
    }

    const showModal = () => {
        if (window.innerWidth > 700) {
            const ItemClassEditModal = document.getElementById('item-class-edit-modal-'+props.id);
            ItemClassEditModal.showModal();
        } else {
            openMobileModal();
        }
    }

    let editButton;
    if ((props.userClass === 99) || (props.userClass === 'manager') || (props.userClass === 'Manager')) {
        editButton =
            <IconButton
                altText='edit'
                backgroundColor='rgba(0,0,0,0)'
                image={require('../../icons/edit_grey.png')}
                clickEvent={showModal}/>
    }

    let ItemClassDeleteButtonContainer;
    if ((props.userClass === 99) || (props.userClass === 'manager') || (props.userClass === 'Manager')) {
        ItemClassDeleteButtonContainer = <ItemClassDeleteButton id={props.id}/>
    }

    if (!props.isExpired) {
        return (
            <div className="item-contents-container">
                <div className='item-contents-container-row'>
                    <h2 className="item-contents-container-tite">{props.groupName}</h2>
                    {editButton}
                </div>
                <h3 className="item-contents-container-intro">{props.intro}</h3>
                {ItemClassDeleteButtonContainer}
                <ItemList 
                    items={props.items} 
                    isLogined={props.isLogined} 
                    userClass={props.userClass}
                    menuClassId={props.id}
                    orderType={props.orderType}/>
                <ItemClassEditModal
                    name={props.groupName}
                    intro={props.intro}
                    priority={props.priority}
                    id={props.id}/>
                <ItemClassEditMobileModal
                    isOn={isMobileModalOn}
                    closeEvent={closeMobileModal}
                    name={props.groupName}
                    intro={props.intro}
                    priority={props.priority}
                    id={props.id}/>
            </div>
        )
    }
}

ItemContentsContainer.defaultProps = {
    groupName: '아이템 그룹',
    intro: '소개',
    items: [
        {image: '', name: '상품명1', price: 2400, minimumOrderQuantity: 1, id: '000', options: [],},
        {image: '', name: '상품명2', price: 42000, minimumOrderQuantity: 1, id: '001', options: [{name:'1호',price:42000},{name:'2호',price:49000},{name:'3호',price:56000}],},
        {image: '', name: '상품명3', price: 2500, minimumOrderQuantity: 3, id: '002', options: [],},
        {image: '', name: '상품명4', price: 2900, minimumOrderQuantity: 4, id: '003', options: [],},
        {image: '', name: '상품명5', price: 3000, minimumOrderQuantity: 10, id: '004', options: [],},
    ],
    isLogined: false,
    isExpired: false,
    userClass: 0,
    priority: 0,
    id: '000',
}