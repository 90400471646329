export const SAVE_ORDERS = "ORDER/SAVE_ORDERS";
export const SAVE_RECENT_ORDERS = "ORDER/SAVE_RECENT_ORDERS";
export const SAVE_ORDER = "ORDER/SAVE_ORDER";

export const saveOrders = (orders) => ({type:SAVE_ORDERS, orders:orders});
export const saveRecentOrders = (recentOrders) => ({type:SAVE_RECENT_ORDERS, recentOrders:recentOrders});
export const saveOrder = (order) => ({type:SAVE_ORDER, order:order});

const initialState = {
    recentOrders: [],
    orders: [],
    order: {
        menus: [],
        customer: {
            _id: '000',
            name: '이름',
            phone: '010-0000-0000',
            email: 'abcd@gmail.com',
        },
        shipping: {
            _id: '000',
            name: '이름',
            phone: '010-0000-0000',
            basicAddress: '서울시 동대문구 이히로 10길',
            detailAddress: '101동 1132호',
            postalCode: '00120',
            request: '',
            tag: '배송지',
            userId: '000',
        },
        mileageUse: 0,
        presentDate: '2012-12-12',
        presentTime: '13:30',
        deliveryDate: '2012-12-12',
        pickupDate: '2012-12-12',
        pickupTime: '13:30',
        type:'pickup',
        status: '결제완료',
        orderPrice: 3000,
        payedMoney: 3000,
        orderId: '000',
        receipt: '',    
        paymentKey: '',
        deliveryId: '000',
    }
}

const order = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ORDERS:
            return {
                ...state,
                orders: action.orders
            }
        case SAVE_RECENT_ORDERS:
            return {
                ...state,
                recentOrders: action.recentOrders
            }
        case SAVE_ORDER:
            return {
                ...state,
                order: action.order
            }
        default:
            return state;
    }
};

export default order;