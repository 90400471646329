import './SimpleOrder.css';

import { useDispatch } from 'react-redux';

import OrderStateSelector from '../OrderStateSelector/OrderStateSelector';

import { updateOrderStatus, getDailyOrderList, getRecentMonthlyOrderList } from '../../functions/manage';
import { saveOrders, saveRecentOrders } from '../../_reducers/order';

export default function SimpleOrder(props) {
    const dispatch = useDispatch();
    let itemNumber = 0;
    props.items.map((item) => {
        if (item.isChecked) {
            itemNumber = itemNumber + 1;
        }
    })

    let representativeItem;
    props.items.map((item) => {
        if (!representativeItem) {
            if (item.isChecked) {
                representativeItem = item;
            }
        }
    })

    let itemRepresentativeName;
    if (representativeItem.option) {
        if (representativeItem.option.name !== 'basic') {
            itemRepresentativeName = representativeItem.name + ' ' + representativeItem.option.name + ' x ' + representativeItem.quantity;
        } else {
            itemRepresentativeName = representativeItem.name + ' x ' + representativeItem.quantity;
        }
    } else {
        itemRepresentativeName = representativeItem.name + ' x ' + representativeItem.quantity;
    }
    
    let itemText;
    if (itemNumber > 1) {
        itemText = itemRepresentativeName + ' 외 ' + String((itemNumber - 1)) + '건';
    } else {
        itemText = itemRepresentativeName;
    }

    let pickupTime;
    if (props.type === 'pickup') {
        pickupTime = String(props.pickupTime);
    }

    let phone;
    if (props.phone.includes('-')) {
        phone = props.phone;
    } else {
        phone = props.phone.substr(0,3) + '-'+props.phone.substr(3,4) + '-' + props.phone.substr(7);
    }

    let date;
    if (props.isDateNeeded) {
        if (props.type === 'pickup') {
            date = String(props.pickupDate) + ' ';
        }
    }

    const StatusChangeEvent = (status) => {
        updateOrderStatus(props.id,status).then(() => {
            getDailyOrderList(props.type,props.date).then((orderList) => {
                dispatch(saveOrders(orderList));
            })
            getRecentMonthlyOrderList(props.type).then(orderList => {
                dispatch(saveRecentOrders(orderList));
            })
        })
    }

    let statusOptions;
    switch (props.type) {
        case "delivery":
            statusOptions = ['결제완료','상품준비중','배송중','배송완료'];
            break;
        case "pickup":
            statusOptions = ['결제완료','상품준비중','픽업대기중','픽업완료'];
            break;
        case "present":
            statusOptions = ['결제완료','상품준비중','배송중','배송완료'];
            break;
        default:
            statusOptions = ['결제완료','상품준비중','픽업대기중','픽업완료'];
            break;
    }

    return (
        <div className='simple-order'>
            <div className='simple-order-contents desktop'>
                <div className='simple-order-row'>
                    <div className='simple-order-column'>
                        <span className='simple-order-text'>{date}{pickupTime}</span>
                        <span className='simple-order-text'>{props.customer.name} ({phone}) / {itemText}</span>
                    </div>
                    <div className='simple-order-state-selector-container'>
                        <OrderStateSelector
                            status={props.status}
                            selectEvent={StatusChangeEvent}
                            options={statusOptions}/>
                    </div>
                </div>
            </div>
            <div className='simple-order-contents mobile'>
                <div className='simple-order-row'>
                    <span className='simple-order-text'>{props.customer.name} ({phone})</span>
                    <span className='simple-order-text'>{date}{pickupTime}</span>
                </div>
                <div className='simple-order-row'>
                    <span className='simple-order-text'>{itemText}</span>
                    <span className='simple-order-text'>{props.totalPrice.toLocaleString()}원</span>
                </div>
                <div className='simple-order-row flex-end'>
                    <div className='simple-order-state-selector-container'>
                        <OrderStateSelector
                            status={props.status}
                            selectEvent={StatusChangeEvent}
                            options={statusOptions}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const defaultClickEvent = () => {
    
}

SimpleOrder.defaultProps = {
    id: '000',
    customer: '장원영',
    items: [
        {name: "레몬마들렌", quantity: 4, price: 2500},
    ],
    totalPrice: 14000,
    address: '경기도 화성시 시청로 102번길11 205동 1604호 (00406)',
    reciever: '장원영',
    phone: '010-0201-1102',
    status: '결제완료',
    clickEvent: defaultClickEvent,
    type: 'delivery',
    isDateNeeded: false,
}