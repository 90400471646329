import './LikeMenu.css';

import { useDispatch } from 'react-redux';

import UnitBoxContainer from '../UnitBoxContainer/UnitBoxContainer';
import CartButton from '../CartButton/CartButton';
import CartMobileButton from '../CartMobileButton/CartMobileButton';
import DeleteCheckModal from '../DeleteCheckModal/DeleteCheckModal';

import { unlikeMenu } from '../../functions/menus';
import { saveUserInfo } from '../../_reducers/user';

export default function LikeMenu(props) {
    const dispatch = useDispatch();

    let LikeMenuCartButton;
    if (window.innerWidth > 700) {
        LikeMenuCartButton = <CartButton 
                                width={50} 
                                height={50}
                                name={props.name}
                                price={props.price}
                                minimumOrderQuantity={props.minimumOrderQuantity}
                                options={props.options}
                                id={props.id}
                                isLogined={props.isLogined}
                                isPurchaseable={props.isPurchaseable}/>
    } else {
        LikeMenuCartButton = <CartMobileButton 
                                width={42}
                                height={42}
                                name={props.name}
                                price={props.price}
                                minimumOrderQuantity={props.minimumOrderQuantity}
                                options={props.options}
                                id={props.id}
                                isLogined={props.isLogined}
                                isPurchaseable={props.isPurchaseable}/>
    }

    const openDeleteCheckModal = () => {
        const LikeMenuDeleteCheckModal = document.getElementById('like-menu-delete-modal-' + props.id);
        LikeMenuDeleteCheckModal.showModal();
    }

    const deleteButtonEvent = () => {
        unlikeMenu(props.id).then(user => dispatch(saveUserInfo(user)));
    }

    let typeName;
    switch(props.orderType) {
        case "delivery":
            typeName = '배송';
            break;
        case "pickup":
            typeName = '픽업';
            break;
        case "present":
            typeName = '선물';
            break;
        default:
            typeName = '배송';
            break;
    }

    return (
        <UnitBoxContainer>
            <div className='like-menu'>
                <img src={props.image} className='like-menu-image' alt='menu'></img>
                <div className='like-menu-name-container'>
                    <span className='like-menu-type'>{typeName}</span>
                    <h3 className='like-menu-name'>{props.name}</h3>
                </div>
                <div className='like-menu-spacer'></div>
                {LikeMenuCartButton}
            </div>
            <button className='like-menu-delete-button' onClick={() => openDeleteCheckModal()}>
                <span className='like-menu-delete-button-text'>삭제하기</span>
            </button>
            <DeleteCheckModal id={'like-menu-delete-modal-' + props.id} deleteEvent={deleteButtonEvent} />
        </UnitBoxContainer>   
    )
}

LikeMenu.defaultProps = {
    image: '',
    name: '상품명',
    price: 2500,
    options: [],
    id: '000',
    isLogined: false,
    isPurchaseable: false,
    orderType: 'delivery',
    minimumOrderQuantity: 1,
}