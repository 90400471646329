import UnitContentsContainer from "../UnitContentsContainer/UnitContentsContainer";
import CustomerInfoBox from "../CustomerInfoBox/CustomerInfoBox";

export default function CustomerInfoContentsContainer(props) {
    return (
        <UnitContentsContainer title='주문자 정보'>
            <CustomerInfoBox
                name={props.name}
                phone={props.phone}
                email={props.email}
                isEditable={props.isEditable}/>
        </UnitContentsContainer>
    )
}

CustomerInfoContentsContainer.defaultProps = {
    name: '',
    phone: '',
    email: '',
    isEditable: true,
}