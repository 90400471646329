import './ItemAddMobileModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MobileModal from '../MobileModal/MobileModal';

import { addMenu, loadMenuClasses } from '../../functions/menus';
import { saveItemClassList } from '../../_reducers/items';

export default function ItemAddMobileModal(props) {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [intro, setIntro] = useState('');
    const [price, setPrice] = useState(0);
    const [tag, setTag] = useState('');

    const initializeInput = () => {
        setName('');
        setIntro('');
        setPrice(0);
        setTag('');
    }

    const menuAddEvent = () => {
        addMenu(name, intro, price, tag, props.menuClassId, props.orderType).then(menu => {
            loadMenuClasses().then(menuClasses => {
                dispatch(saveItemClassList(menuClasses));
            })}
        );       
    }

    let isAddable = (name && intro && price && tag);

    const closeEvent = () => {
        props.closeEvent();
        initializeInput();
    }
    const updateEvent = () => {
        menuAddEvent();
        props.closeEvent();
    }

    const updatePrice = (e) => {
        if (!isNaN(e.nativeEvent.data)) {
            const numberInput = e.target.value.replace(/-/g,'');
            setPrice(numberInput);
        }
    }

    return (
        <MobileModal isOn={props.isOn} 
                     closeEvent={closeEvent} 
                     isCompleteButtonNeeded={isAddable}
                     completeEvent={updateEvent}
                     completeText='추가하기'>
            <div className='item-add-mobile-modal'>
                <span className='item-add-mobile-modal-title'>이름</span>
                <input className='item-add-mobile-modal-input' value={name} onChange={(e) => setName(e.target.value)}></input>

                <span className='item-add-mobile-modal-title'>소개 문구</span>
                <input className='item-add-mobile-modal-input' value={intro} onChange={(e) => setIntro(e.target.value)}></input>

                <span className='item-add-mobile-modal-title'>가격</span>
                <input className='item-add-mobile-modal-input' value={price} onChange={(e) => updatePrice(e)}></input>

                <span className='item-add-mobile-modal-title'>태그</span>
                <input className='item-add-mobile-modal-input' value={tag} onChange={(e) => setTag(e.target.value)}></input>

                <div style={{'minHeight':'20px'}}></div>
            </div>
        </MobileModal>
    )
}

ItemAddMobileModal.defaultProps = {
    name: '',
    phone: '',
    email: '',
    isOn: false,
    id: '000',
}