import './OrderTimeGuideBox.css';

import UnitBoxContainer from '../UnitBoxContainer/UnitBoxContainer';

export default function OrderTimeGuideBox(props) {
    let orderDateTitle;
    if (props.type === 'pickup') {
        orderDateTitle = '픽업 날짜';
    } else {
        orderDateTitle = '퀵 배송 날짜';
    }

    let [year, month, date] = props.date.split('-');
    month = String(Number(month));
    date = String(Number(date));

    let orderTimeTitle;
    if (props.type === 'pickup') {
        orderTimeTitle = '픽업 시간';
    } else {
        orderTimeTitle = '퀵 배송 시간';
    }

    return (
        <UnitBoxContainer>
            <div className='order-time-guide-box'>
                <div className='order-time-guide-box-row'>
                    <span className='order-time-guide-box-text'>
                        {orderDateTitle}
                    </span>
                    <span className='order-time-guide-box-text'>
                        {year}년 {month}월 {date}일
                    </span>
                </div>
                <div className='order-time-guide-box-row'>
                    <span className='order-time-guide-box-text'>
                        {orderTimeTitle}
                    </span>
                    <span className='order-time-guide-box-text'>
                        {props.time}
                    </span>
                </div>
            </div>
        </UnitBoxContainer>
    )
}

OrderTimeGuideBox.defaultProps = {
    date: '2023-01-01',
    time: '13:30',
    type: 'pickup',
}