import UnitContentsContainer from "../UnitContentsContainer/UnitContentsContainer";
import OrderTimeGuideBox from "../OrderTimeGuideBox/OrderTimeGuideBox";

export default function OrderTimeGuideContentsContainer(props) {
    let title;
    if (props.type === 'pickup') {
        title = '픽업 시간 안내';
    } else {
        title = '퀵 배송 시간 안내';
    }

    return (
        <UnitContentsContainer title={title}>
            <OrderTimeGuideBox
                date={props.date}
                time={props.time}
                type={props.type}/>
        </UnitContentsContainer>
    )
}

OrderTimeGuideContentsContainer.defaultProps = {
    date: '2023-01-01',
    time: '13:30',
    type: 'pickup',
}