import './ItemEditMobileModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MobileModal from '../MobileModal/MobileModal';

import { updateMenu } from '../../functions/menus';
import { saveItemInfo } from '../../_reducers/item';

export default function ItemEditMobileModal(props) {
    const dispatch = useDispatch();

    const [name, setName] = useState(props.name);
    const [intro, setIntro] = useState(props.intro);
    const [price, setPrice] = useState(props.price);
    const [minimumOrderQuantity, setMinimumOrderQuantity] = useState(props.minimumOrderQuantity);
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);

    const initializeInput = () => {
        setName(props.name);
        setIntro(props.intro);
        setPrice(props.price);
        setMinimumOrderQuantity(props.minimumOrderQuantity);
        setStartDate(props.startDate);
        setEndDate(props.endDate);
        if (! props.startDate) setStartDate('');
        if (! props.endDate) setEndDate('');
    }

    if (price === 0 && (props.price > 0)) {
        initializeInput();
    }

    const editButtonClickEvent = () => {
        updateMenu(props.id,name,intro,price,minimumOrderQuantity,startDate,endDate).then(menu => {
            dispatch(saveItemInfo(menu));
        })
    }

    let isEditable = (name && intro && price && minimumOrderQuantity);

    const closeEvent = () => {
        props.closeEvent();
        initializeInput();
    }

    const updateEvent = () => {
        editButtonClickEvent();
        props.closeEvent();
    }

    const updateStartDate = (e) => {
        if (!isNaN(e.nativeEvent.data)) {
            const dateInput = e.target.value.replace(/-/g,'');
            if (dateInput.length < 9) {
                if (dateInput.length < 4) {
                    setStartDate(e.target.value);
                } else {
                    if (dateInput.length < 5) {
                        setStartDate(e.target.value+'-');
                    } else {
                        if (dateInput.length < 7) {
                            setStartDate(dateInput.substring(0,4) + '-' + dateInput.substring(4));
                        } else {
                            setStartDate(dateInput.substring(0,4) + '-' + dateInput.substring(4,6) + '-' + dateInput.substring(6));
                        }
                    } 
                } 
            }
        }
        if (e.nativeEvent.data === null) setStartDate(e.target.value);
    }

    const updateEndDate = (e) => {
        if (!isNaN(e.nativeEvent.data)) {
            const dateInput = e.target.value.replace(/-/g,'');
            if (dateInput.length < 9) {
                if (dateInput.length < 4) {
                    setEndDate(e.target.value);
                } else {
                    if (dateInput.length < 5) {
                        setEndDate(e.target.value+'-');
                    } else {
                        if (dateInput.length < 7) {
                            setEndDate(dateInput.substring(0,4) + '-' + dateInput.substring(4));
                        } else {
                            setEndDate(dateInput.substring(0,4) + '-' + dateInput.substring(4,6) + '-' + dateInput.substring(6));
                        }
                    } 
                } 
            }
        }
        if (e.nativeEvent.data === null) setEndDate(e.target.value);
    }

    let DateEditInput;
    if (props.type === 'pickup') {
        DateEditInput = 
        <div>
            <span className='item-edit-mobile-modal-title'>주문 가능 날짜</span>
            <div className='item-edit-mobile-modal-date-input-row'>
                <input className='item-edit-mobile-modal-input' value={startDate} onChange={(e) => updateStartDate(e)} placeholder='2020-01-01'></input>
                <input className='item-edit-mobile-modal-input' value={endDate} onChange={(e) => updateEndDate(e)} placeholder='2020-01-01'></input>
            </div>
        </div>
    }

    return (
        <MobileModal isOn={props.isOn} 
                     closeEvent={closeEvent} 
                     isCompleteButtonNeeded={isEditable}
                     completeEvent={updateEvent}
                     completeText='수정하기'>
            <div className='item-edit-mobile-modal'>
                <span className='item-edit-mobile-modal-title'>메뉴 이름</span>
                <input className='item-edit-mobile-modal-input' value={name} onChange={(e) => setName(e.target.value)}></input>

                <span className='item-edit-mobile-modal-title'>소개 문구</span>
                <input className='item-edit-mobile-modal-input' value={intro} onChange={(e) => setIntro(e.target.value)}></input>

                <span className='item-edit-mobile-modal-title'>가격</span>
                <input className='item-edit-mobile-modal-input' value={price} onChange={(e) => setPrice(e.target.value)}></input>

                <span className='item-edit-mobile-modal-title'>최소 주문 개수</span>
                <input className='item-edit-mobile-modal-input' value={minimumOrderQuantity} onChange={(e) => setMinimumOrderQuantity(e.target.value)}></input>

                {DateEditInput}

                <div style={{'minHeight':'20px'}}></div>
            </div>
        </MobileModal>
    )
}

ItemEditMobileModal.defaultProps = {
    name: '',
    intro: '',
    price: 0,
    minimumOrderQuantity: 1,
    isOn: false,
    id: '000',
}