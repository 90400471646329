import './ItemDescriptionEditMobileModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MobileModal from '../MobileModal/MobileModal';

import { updateMenuDescription } from '../../functions/menus';
import { saveItemInfo } from '../../_reducers/item';

export default function ItemDescriptionEditMobileModal(props) {
    const dispatch = useDispatch();

    const [description, setDescription] = useState(props.description);
    const [isInitialized, setInitialized] = useState(false);

    const initializeInput = () => {
        setDescription(props.description);
    }

    if (!isInitialized & !description && props.description) {
        initializeInput();
        setInitialized(true);
    }

    const editButtonClickEvent = () => {
        updateMenuDescription(props.id,description).then(menu => {
            dispatch(saveItemInfo(menu));
        })
    }

    let isEditable = (description);

    const closeEvent = () => {
        props.closeEvent();
        initializeInput();
    }

    const updateEvent = () => {
        editButtonClickEvent();
        props.closeEvent();
    }

    return (
        <MobileModal isOn={props.isOn} 
                     closeEvent={closeEvent} 
                     isCompleteButtonNeeded={isEditable}
                     completeEvent={updateEvent}
                     completeText='수정하기'>
            <div className='item-description-edit-mobile-modal'>
                <span className='item-description-edit-mobile-modal-title'>상세 문구</span>
                <textarea className='item-description-edit-mobile-modal-textarea' 
                    placeholder='상세 문구를 입력해주세요.'
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)}></textarea>

                <div style={{'minHeight':'20px'}}></div>
            </div>
        </MobileModal>
    )
}

ItemDescriptionEditMobileModal.defaultProps = {
    name: '',
    intro: '',
    price: 0,
    minimumOrderQuantity: 1,
    isOn: false,
    id: '000',
}