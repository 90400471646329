import './ItemClassDeleteButton.css';

import { useDispatch } from 'react-redux';

import DeleteCheckModal from '../DeleteCheckModal/DeleteCheckModal';

import { deleteMenuClass, loadMenuClasses } from '../../functions/menus';
import { saveItemClassList } from '../../_reducers/items';

export default function ItemClassDeleteButton (props) {
    const dispatch = useDispatch();

    const deleteButtonClickEvent = () => {
        const deleteCheckModal = document.getElementById('item-class-delete-check-modal'+props.id);
        deleteCheckModal.showModal();
    }

    const deleteItem = () => {
        deleteMenuClass(props.id).then(menu => {
            loadMenuClasses().then(menuClasses => {
                dispatch(saveItemClassList(menuClasses));
            })
        })
    }

    return (
        <div className='item-class-delete-button-container'>
            <button className='item-class-delete-button' onClick={() => deleteButtonClickEvent()}>
                <span className='item-class-delete-button-text'>
                    삭제하기
                </span>
            </button>
            <DeleteCheckModal
                id={'item-class-delete-check-modal'+props.id}
                deleteEvent={deleteItem}/>
        </div>
        
    )
}

ItemClassDeleteButton.defaultProps = {
    id: '000',
}