import './OrderProcessing.css';

export default function OrderProcessing() {
    return (
        <div className='order-processing-guide'>
            <img className='order-processing-image' src={require('../../icons/loading_orange.png')} alt='loading'></img>
            <div style={{'minHeight':'20px'}}></div>
            <h1 className='order-processing-guide-header1'>
                결제를 진행중입니다.
            </h1>
            <div style={{'minHeight':'10px'}}></div>
            <h3 className='order-processing-guide-header3'>
                잠시만 기다려주세요!
            </h3>
        </div>
    )
}