import './ItemClassAddModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal/Modal';
import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

import { addMenuClass, loadMenuClasses } from '../../functions/menus';
import { saveItemClassList } from '../../_reducers/items';

export default function ItemClassAddModal(props) {
    const dispatch = useDispatch();

    const componentId = 'item-class-add-modal';

    const [name, setName] = useState('');
    const [intro, setIntro] = useState('');
    const [priority, setPriority] = useState('');

    const initializeInput = () => {
        setName('');
        setIntro('');
        setPriority('');
    }

    let orderType;
    switch(window.location.pathname) {
        case '/':
            orderType = 'pickup';
            break;
        case '/pickup':
            orderType = 'pickup';
            break;
        case '/present':
            orderType = 'present';
            break;
        case '/delivery':
            orderType = 'delivery';
            break;
        default:
            orderType = 'pickup';
            break;
    }

    const updateEvent = () => {
        addMenuClass(name,intro,priority,orderType).then(menuClass => {
            initializeInput();
            loadMenuClasses().then(menuClasses => {
                dispatch(saveItemClassList(menuClasses));
            })}
        );       
    }

    let isAddable = (name && intro && (priority || (priority === 0)));

    let AddButton;
    if (isAddable) {
        AddButton =
            <form method="dialog" className='item-class-add-modal-close-button-container'>
                <QuadrangleButton
                    paddingTop={12}
                    paddingBottom={12}
                    borderRadius={14}
                    text='추가하기'
                    clickEvent={updateEvent}/>
            </form>
    }

    return (
        <Modal id={componentId} closeEvent={initializeInput}>
            <div className='item-class-add-modal'>
                <span className='item-class-add-modal-title'>메뉴 클래스 이름</span>
                <input className='item-class-add-modal-input' value={name} onChange={(e) => setName(e.target.value)}></input>

                <span className='item-class-add-modal-title'>소개 문구</span>
                <input className='item-class-add-modal-input' value={intro} onChange={(e) => setIntro(e.target.value)}></input>

                <span className='item-class-add-modal-title'>우선순위</span>
                <input className='item-class-add-modal-input' value={priority} onChange={(e) => setPriority(e.target.value)}></input>

                <div style={{'minHeight':'50px'}}></div>

                {AddButton}
            </div>
        </Modal>
    )
}

ItemClassAddModal.defaultProps = {

}