import axios from "axios";

import { setCookie } from "./cookie";

const {Kakao} = window;
export const moveToKakaoLogin = () =>{
    setCookie("redirectUrl",window.location.pathname,1);
    Kakao.Auth.authorize({
        redirectUri: process.env.REACT_APP_REDIRECT_URL
    })
    setCookie("socialLogin","kakao",1);
}

export const KakaoRedirectHandler = async() => {
    let params = new URL(document.location.toString()).searchParams;
    let code = params.get("code");
    const kakaoLoginResponse = await axios.post('https://api.madinbakery.com/user/kakaologin',
        {
        "code": code,
        "redirectUri": process.env.REACT_APP_REDIRECT_URL
        }
    )
    const user = kakaoLoginResponse.data.user;
    window.history.replaceState({}, null, window.location.pathname);

    setCookie("token",user["token"],1);

    return (
        user
    )
};