import './MenuAddButton.css';

export default function MenuAddButton(props) {
    return (
        <div className='menu-add-button-container'>
            <button className='menu-add-button' onClick={() => props.clickEvent()}>
                <img src={require('../../icons/plus_grey.png')} 
                     className='menu-add-button-plus-icon'
                     alt='plus'></img>
                <span className='menu-add-button-text'>메뉴 추가하기</span>
            </button>
        </div>
    )
}

const defaultClickEvent = () => {
    
}

MenuAddButton.defaultProps = {
    clickEvent: defaultClickEvent,
}