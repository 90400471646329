import './OrderButton.css';

import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

export default function OrderButton(props) {
    if (props.type === 'mobile-fixed-type') {
        return (
            <div className='order-button-container'>
                <div className='order-button widescreen'>
                    <QuadrangleButton
                        textType='h3'
                        text={props.text}
                        paddingTop='10px'
                        paddingBottom='10px'
                        clickEvent={props.clickEvent}
                        clickEventInput={props.clickEventInput}
                        backgroundColor={props.backgroundColor}
                        borderColor={props.borderColor}/>
                </div>
                <div className='mobile-order-button'>
                    <QuadrangleButton
                        textType='h3'
                        text={props.text}
                        paddingTop={14}
                        paddingBottom={14}
                        borderRadius={16}
                        clickEvent={props.clickEvent}
                        clickEventInput={props.clickEventInput}
                        backgroundColor={props.backgroundColor}
                        borderColor={props.borderColor}/>
                </div>
            </div>
            
        )    
    } else {
        return (
            <div className='order-button'>
                <div className='order-button widescreen'>
                    <QuadrangleButton
                        textType='h3'
                        text={props.text}
                        paddingTop='10px'
                        paddingBottom='10px'
                        clickEvent={props.clickEvent}
                        clickEventInput={props.clickEventInput}
                        backgroundColor={props.backgroundColor}
                        borderColor={props.borderColor}/>
                </div>
                <div className='mobile-order-button fixed-type'>
                    <QuadrangleButton
                        textType='h3'
                        text={props.text}
                        paddingTop={14}
                        paddingBottom={14}
                        borderRadius={16}
                        clickEvent={props.clickEvent}
                        clickEventInput={props.clickEventInput}
                        backgroundColor={props.backgroundColor}
                        borderColor={props.borderColor}/>
                </div>
            </div>
        )    
    }
}

const defaultClickEvent = () => {
    
}

OrderButton.defaultProps = {
    clickEvent: defaultClickEvent,
    text: '주문하러 가기',
    type: 'mobile-fixed-type',
    userId: '000',
    totalPrice: 0,
    orderName: '주문명',
    customerName:'이름',
    orderType: 'delivery',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: 'rgba(235,92,46,1)',
    borderColor: 'rgba(235,92,46,1)',
}