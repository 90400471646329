import './LikeMenuList.css';

import LikeMenu from '../LikeMenu/LikeMenu';

export default function LikeMenuList(props) {
    let LikeMenus;
    let LikeMenuAddGuide;

    if (props.likeMenuList && props.items) {
        if ((props.likeMenuList.length > 0) && (props.items.length > 0)) {
            let validLikeMenuCounts = 0;
            LikeMenus = props.likeMenuList.map((likeMenuId) => {
                let likeMenu = props.items.find(e => e._id === likeMenuId); 
                if (likeMenu) {
                    validLikeMenuCounts = validLikeMenuCounts + 1;
                    return (
                        <LikeMenu
                            image={likeMenu.imageUrl}
                            name={likeMenu.name}
                            price={likeMenu.price}
                            minimumOrderQuantity={likeMenu.minimumOrderQuantity}
                            options={likeMenu.options}
                            id={likeMenu._id}
                            isPurchaseable={likeMenu.purchaseable}
                            isLogined={props.isLogined}
                            orderType={likeMenu.orderType}
                            key={'like-menu-'+likeMenu._id}
                    />
                    )
                }
            })
            if (!validLikeMenuCounts) {
                LikeMenuAddGuide =
                    <div className='like-menu-list-add-guide'>
                        <span className='like-menu-list-add-guide-text'>상품 상세페이지에서 좋아요를 눌러보세요.</span>
                    </div>
            }
        } else {
            LikeMenuAddGuide =
                <div className='like-menu-list-add-guide'>
                    <span className='like-menu-list-add-guide-text'>상품 상세페이지에서 좋아요를 눌러보세요.</span>
                </div>
        }
    } else {
        LikeMenuAddGuide =
            <div className='like-menu-list-add-guide'>
                <span className='like-menu-list-add-guide-text'>상품 상세페이지에서 좋아요를 눌러보세요.</span>
            </div>
    }

    return (
        <div className='like-menu-list'>
            {LikeMenus}
            {LikeMenuAddGuide}
        </div>
    )
}

LikeMenuList.defaultProps = {
    likeMenuList: [
        {image:'', name:'상품명1', id:'000', price: 2500, minimumOrderQuantity:1},
        {image:'', name:'상품명2', id:'001', price: 3000, minimumOrderQuantity:3},
        {image:'', name:'상품명3', id:'002', price: 42000, minimumOrderQuantity:1, options:[{name:'1호',price:42000},{name:'2호',price:49000},{name:'3호',price:56000}]},
    ],
    isLogined: true,
    items: [],
}
