import './ItemList.css';

import { useState } from 'react';

import Item from '../Item/Item';
import MenuAddButton from '../MenuAddButton/MenuAddButton';
import ItemAddModal from '../ItemAddModal/ItemAddModal';
import ItemAddMobileModal from '../ItemAddMobileModal/ItemAddMobileModal';

export default function ItemList(props) {
    const [isMenuAddMobileModalOn, setMenuAddMobileModalOn] = useState(false);

    const showItemAddModal = () => {
        if (window.innerWidth > 700) {
            const itemAddModal = document.getElementById('item-add-modal-'+props.menuClassId);
            itemAddModal.showModal();
        } else {
            setMenuAddMobileModalOn(true);
        }
    }

    const closeMobileModal = () => {
        setMenuAddMobileModalOn(false);
    }

    const Items = props.items.map((item,index) => (
        <Item
            image={item.imageUrl}
            name={item.name}
            price={item.price}
            minimumOrderQuantity={item.minimumOrderQuantity}
            options={item.options}
            id={item._id}
            key={'item-'+index+'-'+item._id}
            isPurchaseable={item.purchaseable}
            isLogined={props.isLogined}/>
    ))

    if ((props.userClass === 'manager') || (props.userClass === 99)) {
        Items.push(
            <MenuAddButton clickEvent={showItemAddModal} key={'menu-add-button'+props.menuClassId}/>
        )
    }

    return (
        <div className='item-list'>
            {Items}
            <ItemAddModal menuClassId={props.menuClassId} orderType={props.orderType}/>
            <ItemAddMobileModal 
                menuClassId={props.menuClassId} 
                orderType={props.orderType} 
                isOn={isMenuAddMobileModalOn}
                closeEvent={closeMobileModal}/>
        </div>
    )
}

ItemList.defaultProps = {
    items: [
        {image: '', name: '상품명1', price: 2400, minimumOrderQuantity: 1, id: '000', options: [],},
        {image: '', name: '상품명2', price: 42000, minimumOrderQuantity: 1, id: '001', options: [{name:'1호',price:42000},{name:'2호',price:49000},{name:'3호',price:56000}],},
        {image: '', name: '상품명3', price: 2500, minimumOrderQuantity: 3, id: '002', options: [],},
        {image: '', name: '상품명4', price: 2900, minimumOrderQuantity: 4, id: '003', options: [],},
        {image: '', name: '상품명5', price: 3000, minimumOrderQuantity: 10, id: '004', options: [],},
    ],
    isLogined: false,
    userClass: 0,
    menuClassId: '000',
}