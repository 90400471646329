import './ItemDescriptionEditModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal/Modal';
import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

import { updateMenuDescription } from '../../functions/menus';
import { saveItemInfo } from '../../_reducers/item';

export default function ItemDescriptionEditModal(props) {
    const dispatch = useDispatch();

    const componentId = 'item-description-edit-modal-' + props.id

    const [description, setDescription] = useState(props.description);
    const [isInitialized, setInitialized] = useState(false);

    const initializeInput = () => {
        setDescription(props.description);
    }

    if (!isInitialized & !description && props.description) {
        initializeInput();
        setInitialized(true);
    }

    const updateEvent = () => {
        updateMenuDescription(props.id,description).then(menu => {
            dispatch(saveItemInfo(menu));
        })
    }

    let isEditable = (description);

    let EditButton;
    if (isEditable) {
        EditButton =
            <form method="dialog" className='item-description-edit-modal-close-button-container'>
                <QuadrangleButton
                    paddingTop={12}
                    paddingBottom={12}
                    borderRadius={14}
                    text='수정하기'
                    clickEvent={updateEvent}/>
            </form>
    }

    return (
        <Modal id={componentId} closeEvent={initializeInput}>
            <div className='item-description-edit-modal'>
                <span className='item-description-edit-modal-title'>상세 문구</span>
                <textarea className='item-description-edit-modal-textarea' 
                    placeholder='상세 문구를 입력해주세요.'
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)}></textarea>
                <div style={{'minHeight':'50px'}}></div>

                {EditButton}
            </div>
        </Modal>
    )
}

ItemDescriptionEditModal.defaultProps = {
    description: '상품의 소개를 적어주세요.',
}