export const SAVE_USER_INFO = "USER/SAVE_USER_INFO";
export const LOGIN = "USER/LOGIN";
export const LOGOUT = "USER/LOGOUT";

export const saveUserInfo = (info) => ({type:SAVE_USER_INFO, info:info});
export const login = () => ({type:LOGIN});
export const logout = () => ({type:LOGOUT});

const initialState = {
    isLogined: false,
    name: "이름",
    email: "abcd@gmail.com",
    phone: "010-0000-0000",
    class: 0,
    socialId: "abcdabcd",
    shippingIndex: 0,
    shippings: [],
    orders: [],
    deliveryCart: [],
    pickupCart: [],
    presentCart: [],
    likes: [],
    coupons: [],
    id: '000',
    date: '2023-01-01',
    time: '12:00',
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER_INFO:
            return {
                ...state,
                name: action.info.name,
                email: action.info.email,
                phone: action.info.phone,
                class: action.info.class,
                socialId: action.info.socialId,
                shippings: action.info.shippings,
                orders: action.info.orders.sort(function(a,b) {
                    return (a.createdAt < b.createdAt ? 1: -1)
                }),
                likes: action.info.likes,
                deliveryCart: action.info.deliveryCart,
                pickupCart: action.info.pickupCart,
                presentCart: action.info.presentCart,
                shippingIndex: action.info.shippingIndex,
                id: action.info._id,
                date: action.info.date,
                time: action.info.time,
            }
        case LOGIN:
            return {
                isLogined: true,
            }
        case LOGOUT:
            return {
                isLogined: false,
            }
        default:
            return state;
    }
};

export default user;