import './Order.css';

import { useDispatch } from 'react-redux';

import { updateOrderStatus, getDailyOrderList, getRecentMonthlyOrderList } from '../../functions/manage';
import { saveOrders, saveRecentOrders } from '../../_reducers/order';

export default function Order(props) {
    const dispatch = useDispatch();    
    
    const Items = props.items.map((item,index) => {
        let itemName;
        let itemPrice;
        if (item.option) {
            if (item.option.name !== 'basic') {
                itemName = item.name + ' ' + item.option.name;
                itemPrice = item.option.price;
            } else {
                itemName = item.name;  
                itemPrice = item.price;  
            }
        } else {
            itemName = item.name;
            itemPrice = item.price;  
        }
        if (item.isChecked) {
            return (
                <div className='order-contents-container' key={'order-item-'+index}>
                    <span className='order-items'>{itemName} x {item.quantity}</span>
                    <span className='order-items'>{Number(itemPrice).toLocaleString()}원</span>
                </div>
            )
        }
    })

    

    const StatusButtonClickEvent = (status) => {
        updateOrderStatus(props.id,status).then(() => {
            getDailyOrderList(props.type,props.date).then((orderList) => {
                dispatch(saveOrders(orderList));
            })
            getRecentMonthlyOrderList(props.type).then(orderList => {
                dispatch(saveRecentOrders(orderList));
            })
        })
    }

    let StatusButtons;
    let CancelNotification;
    if (props.status === '결제취소') {
        CancelNotification = 
            <div className='order-canceled-notification-container'>
                <h3 className='order-canceled-notification'>
                    취소된 주문입니다.
                </h3>
            </div>
    } else {
        if (props.orderType === 'delivery') {
            StatusButtons = 
            <div className='order-status-buttons'>
                <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                <StatusButton text='배송중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                <StatusButton text='배송완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
            </div>
        } else if (props.orderType === 'pickup') {
            StatusButtons = 
                <div className='order-status-buttons'>
                    <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='픽업대기중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='픽업완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                </div>
        } else if (props.orderType === 'present') {
            StatusButtons = 
                <div className='order-status-buttons'>
                    <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='배송중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='배송완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                </div>
        } else {
            StatusButtons = 
                <div className='order-status-buttons'>
                    <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='픽업대기중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='픽업완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                </div>
        }
    }

    let ShippingInfo;
    if ((props.type === 'present') || (props.type === 'delivery')) {
        ShippingInfo = 
            <div className='order-items-container'>
                <div className='order-contents-container'>
                    <h3 className='order-contents'>
                        배송지
                    </h3>
                </div>
                <div className='order-contents-container'>
                    <span className='order-items'>[주소] {props.address}</span>
                </div>
                <div className='order-contents-container'>
                    <span className='order-items'>[받는사람] {props.reciever}</span>
                </div>
                <div className='order-contents-container'>
                    <span className='order-items'>[전화번호] {props.phone}</span>
                </div>
            </div>
    }

    let PickupTime;
    if (props.type === 'pickup') {
        if (props.isDateNeeded) {
            PickupTime = 
            <div className='order-contents-container'>
                <h3 className='order-contents'>
                    픽업시간
                </h3>
                <h3 className='order-contents'>
                    {props.pickupDate.split('-')[0]}년 {props.pickupDate.split('-')[1]}월 {props.pickupDate.split('-')[2]}일 {props.pickupTime}
                </h3>
            </div>
        } else {
            PickupTime = 
                <div className='order-contents-container'>
                    <h3 className='order-contents'>
                        픽업시간
                    </h3>
                    <h3 className='order-contents'>
                        {props.pickupTime}
                    </h3>
                </div>
        }
        
    }

    return (
        <div className='order'>
            <div className='order-id-container'>
                <span className='order-id'>주문번호 {props.id}</span>
            </div>
            <div className='order-items-container'>
                <div className='order-contents-container'>
                    <h3 className='order-contents'>
                        주문고객
                    </h3>
                </div>
                <div className='order-contents-container'>
                    <span className='order-items'>[이름] {props.customer.name}</span>
                </div>
                <div className='order-contents-container'>
                    <span className='order-items'>[전화번호] {props.customer.phone}</span>
                </div>
            </div>
            <div className='order-items-container'>
                <div className='order-contents-container'>
                    <h3 className='order-contents'>
                        주문한 메뉴
                    </h3>
                </div>
                {Items}
            </div>
            <div className='order-contents-container'>
                <h3 className='order-contents'>
                    총 가격
                </h3>
                <h3 className='order-contents'>
                    {props.totalPrice.toLocaleString()}원
                </h3>
            </div>
            {ShippingInfo}
            {PickupTime}
            {StatusButtons}
            {CancelNotification}
        </div>
    )
}

function StatusButton(props) {
    if (props.text === props.status) {
        return (
            <button className='order-status-button on'>
                <span className='order-status-button-text on'>{props.text}</span>
            </button>
        )
    } else {
        return (
            <button className='order-status-button off' onClick={() => props.clickEvent(props.text)}>
                <span className='order-status-button-text off'>{props.text}</span>
            </button>
        )
    }
}

const defaultClickEvent = () => {
    
}

Order.defaultProps = {
    isDateNeeded: false,
    id: '000',
    customer: '장원영',
    items: [
        {name: "레몬마들렌", quantity: 4, price: 2500},
    ],
    totalPrice: 14000,
    address: '경기도 화성시 시청로 102번길11 205동 1604호 (00406)',
    reciever: '장원영',
    phone: '010-0201-1102',
    status: '결제완료',
    clickEvent: defaultClickEvent,
    type: 'delivery',
}

StatusButton.defualtProps =  {
    clickEvent: defaultClickEvent,
}