import './ItemOptionsUpdateModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal/Modal';
import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

import { updateMenuOptions, loadMenu } from '../../functions/menus';
import { saveItemInfo } from '../../_reducers/item';

export default function ItemOptionsUpdateModal(props) {
    const dispatch = useDispatch();

    const componentId = 'item-options-update-modal';

    const [options, setOptions] = useState(props.options);

    if (props.options & !options) {
        setOptions(props.options);
    }

    const initializeInput = () => {
        setOptions(props.options);
    }

    const updateEvent = () => {
        updateMenuOptions(props.menuId,options).then(menu => {
            loadMenu(props.menuId).then(menu => {
                dispatch(saveItemInfo(menu));
            })}
        );       
    }

    const updateOptionName = (index,name) => {
        const updatedOptions = options.map((option,optionIndex) => {
            if (optionIndex !== index ) {
                return option;
            } else {
                return {
                    ...option,
                    name: name,
                }
            }
        });
        setOptions(updatedOptions);
    }

    const deleteItemFromOptions = (index) => {
        options.splice(index,1);
        setOptions([...options]);
    }

    const updateOptionPrice = (index,price) => {
        const updatedOptions = options.map((option,optionIndex) => {
            if (optionIndex !== index ) {
                return option;
            } else {
                return {
                    ...option,
                    price: price,
                }
            }
        });
        setOptions(updatedOptions);
    }

    let optionInputs;
    if (options) {
        optionInputs = options.map((option,index) => {
            return (
                <div key={'modal-option'+String({index})}>
                    <div className='item-options-update-modal-row'>
                        <span className='item-options-update-modal-title'>옵션 {index+1}</span>
                        <button className='item-options-update-modal-delete-button' onClick={() => deleteItemFromOptions(index)}>
                            <span className='item-options-update-modal-delete-button-icon'>삭제하기</span>
                        </button>
                    </div>
                    <div className='item-options-update-modal-input-container'>
                        <input className='item-options-update-modal-input' value={option.name} onChange={(e) => updateOptionName(index,e.target.value)}></input>
                        <input className='item-options-update-modal-input' value={option.price} onChange={(e) => updateOptionPrice(index,e.target.value)}></input>
                    </div>
                </div>
            )            
        })
    }

    let isUpdatable = true;
    options.map((option) => {
        if (!option.name || !option.price) {
            isUpdatable = false;
        }
    })
    
    let UpdateButton;
    if (isUpdatable) {
        UpdateButton =
            <form method="dialog" className='item-options-update-modal-close-button-container'>
                <QuadrangleButton
                    paddingTop={12}
                    paddingBottom={12}
                    borderRadius={14}
                    text='수정하기'
                    clickEvent={updateEvent}/>
            </form>
    }
    

    const addOptions = () => {        
        setOptions([...options, {"name":"","price":0}]);
    }

    const OptionAddButton = 
        <div className='item-options-update-modal-add-button-container'>
            <button className='item-options-update-modal-add-button' onClick={() => addOptions()}>
                <img className='item-options-update-modal-add-button-icon'
                     src={require('../../icons/plus_grey.png')}></img>
                <span className='item-options-update-modal-add-button-text'>옵션 추가하기</span>
            </button>
        </div>

    return (
        <Modal id={componentId} closeEvent={initializeInput}>
            <div className='item-options-update-modal'>
                <div className='item-options-update-modal-inputs'>
                    {optionInputs}
                    {OptionAddButton}
                </div>
                <div style={{'minHeight':'80px'}}></div>

                {UpdateButton}
            </div>
        </Modal>
    )
}

ItemOptionsUpdateModal.defaultProps = {
    menuId: '000',
}