import './Counter.css';

import { useState } from 'react';

export default function Counter(props) {
    const [count, setCount] = useState(props.count);
    const [onCountEdit, setOnCountEdit] = useState(false);

    let MinusButtonImage;
    if (props.count === props.minimumQuantity) {
        MinusButtonImage =
            <img src={require('../../icons/minus_grey.png')} alt='-' className='counter-button-image'></img>
    } else {
        MinusButtonImage =
            <img src={require('../../icons/minus_black.png')} alt='-' className='counter-button-image'></img>
    }

    const updateCount = (e) => {
        if (!isNaN(e.nativeEvent.data)) {
            const countInput = e.target.value.replace(/-/g,'');
            setCount(countInput);
        }
    }

    const completeUpdatingCount = (e) => {
        if (count >= props.minimumQuantity) {
            props.inputEvent(count);
        } else {
            props.inputEvent(props.minimumQuantity);
        }
        setOnCountEdit(false);
    }

    const startEdit = (e) => {
        setOnCountEdit(true);
        setCount(props.count);
    }

    let counterInput;
    if (onCountEdit) {
        counterInput =
            <input type='text' className='counter-count-input' value={count} onChange={(e) => updateCount(e)} onBlur={(e)=>completeUpdatingCount(e)}></input>
    } else {
        counterInput =
            <input type='text' className='counter-count-input' value={props.count} onChange={(e) => updateCount(e)} onFocus={(e) => startEdit(e)}></input>
    }
    
    return (
        <div className='counter'>
            <button className='counter-button' onClick={() => props.decreaseEvent()}>
                {MinusButtonImage}
            </button>
            {counterInput}
            <button className='counter-button' onClick={() => props.increaseEvent()}>
                <img src={require('../../icons/plus_black.png')} alt='+' className='counter-button-image'></img>
            </button>
        </div>
    )
}

const defaultClickEvent = () => {
    
}

Counter.defaultProps = {
    count: 1,
    minimumQuantity: 1,
    increaseEvent: defaultClickEvent,
    decreaseEvent: defaultClickEvent,
    inputEvent: defaultClickEvent,
}