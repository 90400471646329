import './ItemClassAddMobileModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MobileModal from '../MobileModal/MobileModal';

import { addMenuClass, loadMenuClasses } from '../../functions/menus';
import { saveItemClassList } from '../../_reducers/items';

export default function ItemClassAddMobileModal(props) {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [intro, setIntro] = useState('');
    const [priority, setPriority] = useState('');

    const initializeInput = () => {
        setName('');
        setIntro('');
        setPriority('');
    }

    let orderType;
    switch(window.location.pathname) {
        case '/':
            orderType = 'pickup';
            break;
        case '/pickup':
            orderType = 'pickup';
            break;
        case '/present':
            orderType = 'present';
            break;
        case '/delivery':
            orderType = 'delivery';
            break;
        default:
            orderType = 'pickup';
            break;
    }

    const itemClassAddEvent = () => {
        addMenuClass(name,intro,priority,orderType).then(menuClass => {
            initializeInput();
            loadMenuClasses().then(menuClasses => {
                dispatch(saveItemClassList(menuClasses));
            })}
        );       
    }

    let isAddable = (name && intro && (priority || (priority === 0)));

    const closeEvent = () => {
        props.closeEvent();
        initializeInput();
    }
    const updateEvent = () => {
        itemClassAddEvent();
        props.closeEvent();
    }

    return (
        <MobileModal isOn={props.isOn} 
                     closeEvent={closeEvent} 
                     isCompleteButtonNeeded={isAddable}
                     completeEvent={updateEvent}
                     completeText='수정하기'>
            <div className='item-class-add-mobile-modal'>
                <span className='item-class-add-mobile-modal-title'>메뉴 클래스 이름</span>
                <input className='item-class-add-mobile-modal-input' value={name} onChange={(e) => setName(e.target.value)}></input>

                <span className='item-class-add-mobile-modal-title'>소개 문구</span>
                <input className='item-class-add-mobile-modal-input' value={intro} onChange={(e) => setIntro(e.target.value)}></input>

                <span className='item-class-add-mobile-modal-title'>우선순위</span>
                <input className='item-class-add-mobile-modal-input' value={priority} onChange={(e) => setPriority(e.target.value)}></input>

                <div style={{'minHeight':'20px'}}></div>
            </div>
        </MobileModal>
    )
}

ItemClassAddMobileModal.defaultProps = {
    name: '',
    phone: '',
    email: '',
    isOn: false,
    id: '000',
}