import './CartMobileModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MobileModal from '../MobileModal/MobileModal';
import Counter from '../Counter/Counter';
import CartAddButton from '../CartAddButton/CartAddButton';

import { addMenuToCart } from '../../functions/menus';
import { saveUserInfo } from '../../_reducers/user';

export default function CartMobileModal(props) {
    const dispatch = useDispatch();

    const [count, setCount] = useState(props.minimumOrderQuantity);
    const [option, setOption] = useState({"name":"basic","price":0});
    const [price, setPrice] = useState(props.price);

    const addItemToCart = () => {
        let isOptionSelected = true;
        
        if (props.options) {
            if (props.options.length > 0) {
                if (option.name === "basic") {
                    isOptionSelected = false;
                }
            }
        }
        if (isOptionSelected) {
            addMenuToCart(props.id,count,option).then(user => dispatch(saveUserInfo(user)));
        } else {
            addMenuToCart(props.id,count,props.options[0]).then(user => dispatch(saveUserInfo(user)));
        }
        props.closeEvent();
        setCount(props.minimumOrderQuantity);
        props.popupEvent();
    }

    const increaseCount = () => {
        setCount(count + 1);
    }

    const decreaseCount = () => {
        if (count > props.minimumOrderQuantity) {
            setCount(count - 1);
        }
    }

    const totalPrice = count * price;

    const selectOption = (e) => {
        setOption(
            {"name":props.options[e.target.selectedIndex-1]["name"],
             "price":props.options[e.target.selectedIndex-1]["price"]});
        setPrice(props.options[e.target.selectedIndex-1]["price"]);
    }

    const isOptionItem = Array.isArray(props.options) && (props.options.length > 0);
    let optionsContainer;
    if (isOptionItem) {
        const Options = props.options.map((option) => (
            <option 
                className='mobile-cart-modal-option'
                value={option["name"]} 
                key={option["name"]}>
                {option["name"]} - {option["price"].toLocaleString()}원
            </option>
        ))
        optionsContainer =
            <select name='option' className='mobile-cart-modal-option-container' onChange={selectOption}>
                <option value="" selected disabled hidden>옵션을 선택해주세요.</option>
                {Options}
            </select>
    }
    
    let isOptionSelected = true;
    if (props.options && props.isLogined) {
        if (props.options.length > 0) {
            if (option.name === 'basic') {
                isOptionSelected = false;
            }
        }
    }    
    
    return (
        <MobileModal id={'mobile-cart-modal-'+props.id} isOn={props.isOn} closeEvent={props.closeEvent}>
            {optionsContainer}
            <div className='mobile-cart-modal-row top'>
                <h2 className='mobile-cart-modal-item-name'>{props.name}</h2>
                <h2 className='mobile-cart-modal-item-price'>{price.toLocaleString()}원</h2>
            </div>
            <div className='mobile-cart-modal-row middle'>
                <Counter 
                    minimumQuantity={props.minimumOrderQuantity}
                    count={count}
                    increaseEvent={increaseCount}
                    decreaseEvent={decreaseCount}
                    inputEvent={setCount}/>
            </div>
            <div className='mobile-cart-modal-row bottom'>
                <h1 className='mobile-cart-modal-total-price'>{totalPrice.toLocaleString()}원</h1>
            </div>
            <CartAddButton
                paddingTop={14} 
                paddingBottom={14}
                borderRadius={16} 
                textType='h3' 
                cartAddAction={addItemToCart}
                isOptionSelected={isOptionSelected}/>
            <div className='mobile-cart-modal-row-spacer'></div>
        </MobileModal>
    )
}

CartMobileModal.defaultProps = {
    name: "상품명",
    price: 2500,
    minimumOrderQuantity: 1,
    id: '000',
    options: [],
    isOn: false,
}