import './StoreInfo.css';

export default function StoreInfo() {
    return (
        <div className='store-info'>
            <div className='store-info-container'>
                <div className='store-info-row'>
                    <h2 className='store-info-title'>고객문의</h2>
                    <div style={{'minWidth':'10px'}}></div>
                    <h3 className='store-info-h3'>매일 12:00 ~ 20:00</h3>
                </div>
                <div style={{"minHeight":"10px"}}></div>
                <h2 className='store-info-title'>0507-1356-4770 </h2>
                <div style={{"minHeight":"20px"}}></div>
                <div className='store-info-row'>
                    <SocialQuestionButton
                        image={require('../../icons/kakao_square.png')}
                        link='http://pf.kakao.com/_Vjvkb'></SocialQuestionButton>
                    <div style={{'minWidth':'7px'}}></div>
                    <SocialQuestionButton
                        image={require('../../icons/instagram_square.png')}
                        link='https://www.instagram.com/madin_bakery_/'></SocialQuestionButton>
                </div>
            </div>
            <div className='store-info-container'>
                <div className='store-info-row'>
                    <a
                        className='store-info-guide-button'
                        href={'/termsofuser'}>
                        <span className='store-info-guide-button-text'>
                            {'이용약관'}
                        </span>
                    </a>
                    <div style={{"minWidth":"8px"}}></div>
                    <a
                        className='store-info-guide-button'
                        href={'/privacypolicy'}>
                        <span className='store-info-guide-button-text'>
                            {'개인정보처리방침'}
                        </span>
                    </a>
                </div>
                <div style={{"minHeight":"10px"}}></div>
                <div className='store-info-row'>
                    <span className='store-info-text'>상호명 : 마딘베이커리 | 사업자등록번호: 372-36-01219</span>
                </div>
                <div style={{"minHeight":"5px"}}></div>
                <div className='store-info-row'>
                    <span className='store-info-text'>통신판매업: 제 2023-고양덕양구-1435 호</span>
                </div>
                <div style={{"minHeight":"5px"}}></div>
                <div className='store-info-row'>
                    <span className='store-info-text'>주소: 경기도 고양시 덕양구 원흥1로 50 101호</span>
                </div>
                <div style={{"minHeight":"5px"}}></div>
                <div className='store-info-row'>
                    <span className='store-info-text'>개인정보보호책임자 및 대표자: 이지원</span>
                </div>
            </div>
        </div>
    )
}

function SocialQuestionButton(props) {
    return (
        <a 
            className='store-info-social-question-button'
            href={props.link}
            target='_blank'>
            <img 
                className='store-info-social-question-button-image' 
                src={props.image}
                alt='social-question'></img>
        </a>
    )
}

function GuideButton(props) {
    return (
        <a
            className='store-info-guide-button'
            href={props.link}
            target='_blank'>
            <span className='store-info-guide-button-text'>
                {props.text}
            </span>
        </a>
    )
}