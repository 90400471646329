import './CartItemList.css';

import CartItem from '../CartItem/CartItem';

export default function CartItemList(props) {
    let CartItems;
    let CartAddGuide;

    if (props.cartItemList) {
        if (props.cartItemList.length > 0) {
            CartItems = props.cartItemList.map((cartItem,index) => {
                return (
                    <CartItem
                        id={cartItem._id}
                        isChecked={cartItem.isChecked}
                        name={cartItem.name}
                        itemImage={cartItem.imageUrl}
                        checkedImage=''
                        uncheckedImage=''
                        price={cartItem.price}
                        count={cartItem.quantity}
                        option={cartItem.option}
                        minimumOrderQuantity={cartItem.minimumOrderQuantity}
                        orderType={cartItem.orderType}
                        key={'cart-item-'+index}/>
                )
            })
        } else {
            CartAddGuide =
                <div className='cart-item-list-add-guide'>
                    <span className='cart-item-list-add-guide-text'>장바구니에 상품을 추가해주세요.</span>
                </div>
        }
    } else {
        CartAddGuide =
            <div className='cart-item-list-add-guide'>
                <span className='cart-item-list-add-guide-text'>장바구니에 상품을 추가해주세요.</span>
            </div>
    }

    return (
        <div className='cart-item-list'>
            {CartItems}
            {CartAddGuide}
        </div>
    )
}

CartItemList.defaultProps = {
    cartItemList: [
        {id: '000', isChecked: true, name: '상품명1', price: 2500, count: 1},
        {id: '001', isChecked: true, name: '상품명2', price: 3000, count: 1},
        {id: '002', isChecked: true, name: '상품명3', price: 2100, count: 3},
        {id: '003', isChecked: true, name: '상품명4', price: 2800, count: 1},
        {id: '004', isChecked: true, name: '상품명5', price: 32000, count: 2},
    ]
}