import './ShippingBox.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import UnitBoxContainer from '../UnitBoxContainer/UnitBoxContainer';
import CheckButton from '../CheckButton/CheckButton';
import ShippingEditModal from '../ShippingEditModal/ShippingEditModal';
import ShippingEditMobileModal from '../ShippingEditMobileModal/ShippingEditMobileModal';
import DeleteCheckModal from '../DeleteCheckModal/DeleteCheckModal';

import { deleteShipping, selectShippingIndex } from '../../functions/user';
import { saveUserInfo, } from '../../_reducers/user';

export default function ShippingBox(props) {
    const dispatch = useDispatch();

    const [isMobileModalOn, setMobileModalOn] = useState(false);

    const openDeleteCheckModal = () => {
        const ShippingDeleteCheckModal = document.getElementById('shipping-box-delete-modal-' + props.id);
        ShippingDeleteCheckModal.showModal();
    }

    const openMobileModal = () => {
        setMobileModalOn(true);
    }

    const closeMobileModal = () => {
        setMobileModalOn(false);
    }

    const showShippingEditModal = () => {
        if (window.innerWidth > 700) {
            const ShippingEditModal = document.getElementById('shipping-edit-modal-' + props.id);
            ShippingEditModal.showModal();
        } else {
            openMobileModal();
        }
    }

    let EditButtons;
    if (props.isEditable) {
        EditButtons =
            <div className='shipping-box-button-container'>
                <button className='shipping-box-button' onClick={() => showShippingEditModal()}>
                    <span className='shipping-box-button-text'>수정하기</span>
                </button>
                <button className='shipping-box-button' onClick={() => openDeleteCheckModal()}>
                    <span className='shipping-box-button-text'>삭제하기</span>
                </button>
            </div>
    }

    const checkButtonEvent = () => {
        selectShippingIndex(props.index).then((user) => dispatch(saveUserInfo(user)));
    }

    let CheckBox;
    if (props.isCheckable) {
        CheckBox =
            <div className='shipping-box-check-button-container'>
                <CheckButton size={30} isOn={props.isOn} clickEvent={checkButtonEvent}></CheckButton>
            </div>
    }

    const deleteButtonClickEvent = () => {
        deleteShipping(props.id).then((user) => dispatch(saveUserInfo(user)));
        selectShippingIndex(0).then((user) => dispatch(saveUserInfo(user)));
    }

    let Request;
    if (props.request) {
        if (props.request !== " ") {
            Request =
            <span className='shipping-box-text'>
                "{props.request}"
            </span>
        }
    }

    return (
        <UnitBoxContainer>
            <div className='shipping-box'>
                <div className='shipping-box-tag'>
                    <span className='shipping-box-tag-text'>
                        {props.tag}
                    </span>
                </div>
                <div className='shipping-box-text-container'>
                    <span className='shipping-box-text'>
                        {props.basicAddress} {props.detailAddress}
                    </span>
                    <span className='shipping-box-text'>
                        {props.name}, {props.phone}
                    </span>
                    {Request}
                </div>
            </div>
            {CheckBox}
            {EditButtons}
            <ShippingEditModal 
                receiver={props.name} 
                phone={props.phone} 
                basicAddress={props.basicAddress} 
                detailAddress={props.detailAddress} 
                id={props.id}
                tag={props.tag}
                request={props.request}/>
            <ShippingEditMobileModal 
                isOn={isMobileModalOn} 
                closeEvent={closeMobileModal}
                receiver={props.name} 
                phone={props.phone} 
                basicAddress={props.basicAddress} 
                detailAddress={props.detailAddress} 
                id={props.id}
                tag={props.tag}
                request={props.request}/>
            <DeleteCheckModal id={'shipping-box-delete-modal-' + props.id} deleteEvent={deleteButtonClickEvent}/>
        </UnitBoxContainer>
    )
}

ShippingBox.defaultProps = {
    tag: '배송지',
    basicAddress: '도로명 주소',
    detailAddress: '상세 주소',
    name: '이름',
    phone: '010-0000-0000',
    request: '',
    id: '000',
    isEditable: true,
    isCheckable: true,
    isOn: false,
    index: 0,
}