import './OrderPickupTimeChangeModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal/Modal';
import TimeSelector from '../TimeSelector/TimeSelector';
import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

import { updatePickupTime } from '../../functions/order';
import { authUser } from '../../functions/auth';
import { saveUserInfo } from '../../_reducers/user';

export default function OrderPickupTimeChangeModal(props) {
    const dispatch = useDispatch();
    const componentId = 'order-pickup-time-change-modal-'+ props.id; 
    const [selectedTime, setSelectedTime] = useState('12:00');

    const timeSelectEvent = (time) => {
        setSelectedTime(time);
    }

    const changeButtonClickEvent = () => {
        updatePickupTime(props.id, selectedTime).then(
            () => {
                authUser().then((user) => dispatch(saveUserInfo(user)));
            }
        );   
    }

    const ChangeButton =
        <form method="dialog" className='order-pickup-time-change-modal-change-button-container'>
            <QuadrangleButton
                paddingTop={12}
                paddingBottom={12}
                borderRadius={14}
                text='변경하기'
                clickEvent={changeButtonClickEvent}/>
        </form>

    return (
        <Modal id={componentId}>
            <div className='order-pickup-time-change-modal-contents'>
                <div className='order-pickup-time-change-modal-title-row'>
                    <h3 className='order-pickup-time-change-modal-title'>픽업 시간 변경</h3>
                </div>
                <div style={{'minHeight':'20px'}}></div>
                <div className='order-pickup-time-change-modal-row'>
                    <TimeSelector titleType='span' selectEvent={timeSelectEvent}/>
                </div>
                <div style={{'minHeight':'60px'}}></div>
                {ChangeButton}
            </div>
        </Modal>
    )
}

OrderPickupTimeChangeModal.defaultProps = {
    id: '000',
}