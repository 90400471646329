export const SAVE_ITEM_LIST = "ITEMS/SAVE_ITEM_LIST";
export const SAVE_ITEM_CLASS_LIST = "ITEMS/SAVE_ITEM_CLASS_LIST";

export const saveItemList = (list) => ({type:SAVE_ITEM_LIST, list:list});
export const saveItemClassList = (classList) => ({type:SAVE_ITEM_CLASS_LIST, classList:classList});

const initialState = {
    itemList: [],
    itemClassList: [],
}

const items = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ITEM_LIST:
            return {
                ...state,
                itemList: action.list,
            }
        case SAVE_ITEM_CLASS_LIST:
            return {
                ...state,
                itemClassList: action.classList.sort(function(a,b) {
                    return (a.priority > b.priority? 1: -1)
                }),
            }
        default:
            return state;
    }
};

export default items;